@font-face {
	font-family: 'Roboto';
	src: url('./../core/css/fonts/roboto/Roboto-Regular.eot');
	src: url('./../core/css/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		url('./../core/css/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
		url('./../core/css/fonts/roboto/Roboto-Regular.woff') format('woff'),
		url('./../core/css/fonts/roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
