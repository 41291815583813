// app global css in SCSS form
/* source-sans-pro-300 - latin */
/* source-sans-pro-200 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 200;
	src: url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
		url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.woff') format('woff'),
		/* Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-300 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 300;
	src: url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
		url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.woff') format('woff'),
		/* Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
		url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src: url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
		url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.woff') format('woff'),
		/* Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src: url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
		url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.woff') format('woff'),
		/* Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 900;
	src: url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
		url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.woff') format('woff'),
		/* Modern Browsers */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

* {
	font-family: 'Source Sans Pro';
}
