@font-face {
  font-family: "iconfont"; /* Project id 1983055 */
  src: url('//at.alicdn.com/t/font_1983055_f2zwdhdd06s.woff2?t=1649483428445') format('woff2'),
  url('//at.alicdn.com/t/font_1983055_f2zwdhdd06s.woff?t=1649483428445') format('woff'),
  url('//at.alicdn.com/t/font_1983055_f2zwdhdd06s.ttf?t=1649483428445') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.juanhr-organizationManagement:before {
  content: "\e697";
}

.juanhr-personnel:before {
  content: "\e65b";
}

.juanhr-nacbia:before {
  content: "\e661";
}

.juanhr-pwd:before {
  content: "\e662";
}

.juanhr-settings:before {
  content: "\e660";
}

.juanhr-logout:before {
  content: "\e606";
}

.juanhr-f-about:before {
  content: "\e65d";
}

.juanhr-connectdevice:before {
  content: "\e65f";
}

.juanhr-user:before {
  content: "\e602";
}

.juanhr-privacy:before {
  content: "\e604";
}

.juanhr-person:before {
  content: "\e65c";
}

.juanhr-deals:before {
  content: "\e65a";
}

.juanhr-carret-right:before {
  content: "\e653";
}

.juanhr-carret-left:before {
  content: "\e659";
}

.juanhr-logs:before {
  content: "\e60d";
}

.juanhr-home-basket:before {
  content: "\e60a";
}

.juanhr-record:before {
  content: "\e603";
}

.juanhr-item-sold-dashboard:before {
  content: "\e64e";
}

.juanhr-refund-dashboard:before {
  content: "\e64f";
}

.juanhr-sales-dashboard:before {
  content: "\e650";
}

.juanhr-pricelist:before {
  content: "\e651";
}

.juanhr-filter:before {
  content: "\e652";
}

.juanhr-close-z:before {
  content: "\e654";
}

.juanhr-printz:before {
  content: "\e655";
}

.juanhr-posdailyreport:before {
  content: "\e656";
}

.juanhr-posmonthlyreport:before {
  content: "\e657";
}

.juanhr-printx:before {
  content: "\e658";
}

.juanhr-transaction:before {
  content: "\e65e";
}

.juanhr-trending-up:before {
  content: "\e64a";
}

.juanhr-button-menu-round:before {
  content: "\e64b";
}

.juanhr-trending-down:before {
  content: "\e64c";
}

.juanhr-attendanceStatistics:before {
  content: "\e64d";
}

.juanhr-signout:before {
  content: "\e63d";
}

.juanhr-carret-up:before {
  content: "\e61c";
}

.juanhr-close:before {
  content: "\e63c";
}

.juanhr-carret-down:before {
  content: "\e649";
}

.juanhr-backspace:before {
  content: "\e648";
}

.juanhr-back:before {
  content: "\e642";
}

.juanhr-minus:before {
  content: "\e647";
}

.juanhr-sheet-close:before {
  content: "\e63e";
}

.juanhr-taxes:before {
  content: "\e646";
}

.juanhr-tag:before {
  content: "\e601";
}

.juanhr-tips:before {
  content: "\e605";
}

.juanhr-cart:before {
  content: "\e607";
}

.juanhr-history:before {
  content: "\e608";
}

.juanhr-clipboard:before {
  content: "\e609";
}

.juanhr-pie-graph:before {
  content: "\e60b";
}

.juanhr-employee-audit:before {
  content: "\e60c";
}

.juanhr-help:before {
  content: "\e618";
}

.juanhr-sheet-check:before {
  content: "\e61e";
}

.juanhr-inventory:before {
  content: "\e640";
}

.juanhr-schedule-time:before {
  content: "\e641";
}

.juanhr-bar-graph:before {
  content: "\e643";
}

.juanhr-button-menu:before {
  content: "\e644";
}

.juanhr-calendar:before {
  content: "\e645";
}

.juanhr-adjustment:before {
  content: "\e60e";
}

.juanhr-attendanceMonitoring:before {
  content: "\e60f";
}

.juanhr-thirteenthMonthPay:before {
  content: "\e610";
}

.juanhr-allowance:before {
  content: "\e611";
}

.juanhr-branchAssignment:before {
  content: "\e612";
}

.juanhr-biometric:before {
  content: "\e613";
}

.juanhr-bulk-download:before {
  content: "\e614";
}

.juanhr-bulk-upload:before {
  content: "\e615";
}

.juanhr-checkbox-active:before {
  content: "\e616";
}

.juanhr-camera:before {
  content: "\e617";
}

.juanhr-checkbox-inactive:before {
  content: "\e619";
}

.juanhr-check-active:before {
  content: "\e61a";
}

.juanhr-check-inactive:before {
  content: "\e61b";
}

.juanhr-configuration:before {
  content: "\e61d";
}

.juanhr-employee:before {
  content: "\e61f";
}

.juanhr-exclude:before {
  content: "\e620";
}

.juanhr-edit:before {
  content: "\e621";
}

.juanhr-delete:before {
  content: "\e622";
}

.juanhr-download:before {
  content: "\e623";
}

.juanhr-dtr:before {
  content: "\e624";
}

.juanhr-dashboard:before {
  content: "\e625";
}

.juanhr-folder:before {
  content: "\e626";
}

.juanhr-notification:before {
  content: "\e627";
}

.juanhr-include:before {
  content: "\e628";
}

.juanhr-loan:before {
  content: "\e629";
}

.juanhr-payroll:before {
  content: "\e62a";
}

.juanhr-memoAndNotice:before {
  content: "\e62b";
}

.juanhr-overtimeAndHoliday:before {
  content: "\e62c";
}

.juanhr-leave:before {
  content: "\e62d";
}

.juanhr-masterlist:before {
  content: "\e62e";
}

.juanhr-question:before {
  content: "\e62f";
}

.juanhr-status-check:before {
  content: "\e630";
}

.juanhr-search:before {
  content: "\e631";
}

.juanhr-schedule:before {
  content: "\e632";
}

.juanhr-serviceRecord:before {
  content: "\e633";
}

.juanhr-upload:before {
  content: "\e634";
}

.juanhr-benefit:before {
  content: "\e635";
}

.juanhr-burger-menu:before {
  content: "\e636";
}

.juanhr-warning:before {
  content: "\e637";
}

.juanhr-information:before {
  content: "\e638";
}

.juanhr-add:before {
  content: "\e639";
}

.juanhr-store:before {
  content: "\e63a";
}

.juanhr-flag:before {
  content: "\e63b";
}

.juanhr-adjustmentlist1:before {
  content: "\e63f";
}

