@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  /* Project id 2799809 */
  src: url("../assets/fonts/iconfont/iconfont.woff2?t=1641191413704") format("woff2"), url("../assets/fonts/iconfont/iconfont.woff?t=1641191413704") format("woff"), url("../assets/fonts/iconfont/iconfont.ttf?t=1641191413704") format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-widgets:before {
  content: "";
}

.icon-devices:before {
  content: "";
}

.icon-open-person:before {
  content: "";
}

.icon-hands-helping:before {
  content: "";
}

.icon-box-close:before {
  content: "";
}

.icon-question-answer:before {
  content: "";
}

.icon-tablet:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-settings-antenna:before {
  content: "";
}

.icon-username:before {
  content: "";
}

.icon-desktop:before {
  content: "";
}

.icon-mobile:before {
  content: "";
}

.icon-user-lock:before {
  content: "";
}

.icon-notification-solid:before {
  content: "";
}

.icon-history:before {
  content: "";
}

.icon-network:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-log-out:before {
  content: "";
}

.icon-crispy-settings:before {
  content: "";
}

.icon-laptop:before {
  content: "";
}

.icon-metro-list:before {
  content: "";
}

.icon-check-list:before {
  content: "";
}

.icon-check-box:before {
  content: "";
}

.icon-close-circle:before {
  content: "";
}

.icon-fingerprint:before {
  content: "";
}

.icon-face-recognition:before {
  content: "";
}

.icon-help:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-message:before {
  content: "";
}

.icon-name:before {
  content: "";
}

.icon-a-mobilephone:before {
  content: "";
}

.icon-notification:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

@font-face {
  font-family: "iconfont";
  /* Project id 2799809 */
  src: url("//at.alicdn.com/t/font_2799809_iu84vk1ixe.woff2?t=1644633788566") format("woff2"), url("//at.alicdn.com/t/font_2799809_iu84vk1ixe.woff?t=1644633788566") format("woff"), url("//at.alicdn.com/t/font_2799809_iu84vk1ixe.ttf?t=1644633788566") format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-local-activity:before {
  content: "";
}

.icon-magnify:before {
  content: "";
}

.icon-activity:before {
  content: "";
}

.icon-dashboard:before {
  content: "";
}

.icon-add:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-burger:before {
  content: "";
}

.icon-core:before {
  content: "";
}

.icon-widgets:before {
  content: "";
}

.icon-devices:before {
  content: "";
}

.icon-open-person:before {
  content: "";
}

.icon-hands-helping:before {
  content: "";
}

.icon-box-close:before {
  content: "";
}

.icon-question-answer:before {
  content: "";
}

.icon-tablet:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-settings-antenna:before {
  content: "";
}

.icon-username:before {
  content: "";
}

.icon-desktop:before {
  content: "";
}

.icon-mobile:before {
  content: "";
}

.icon-user-lock:before {
  content: "";
}

.icon-history:before {
  content: "";
}

.icon-network:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-log-out:before {
  content: "";
}

.icon-crispy-settings:before {
  content: "";
}

.icon-laptop:before {
  content: "";
}

.icon-metro-list:before {
  content: "";
}

.icon-check-list:before {
  content: "";
}

.icon-check-box:before {
  content: "";
}

.icon-close-circle:before {
  content: "";
}

.icon-fingerprint:before {
  content: "";
}

.icon-face-recognition:before {
  content: "";
}

.icon-help:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-message:before {
  content: "";
}

.icon-name:before {
  content: "";
}

.icon-a-mobilephone:before {
  content: "";
}

.icon-notification:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

/**
  Core Customed Class Names for Background, Text Colors

  NOTE  : Refer to core.variables.scss for variable references
  USAGE : eg. bg-accent-0, bg-hover-accent-0, text-accent-0, text-hover-accent-0
*/
/**
  All switchable colors for dark and light mode will be declared here.
  For adding new color conditions you can refer to the classes declared below.
*/
.core-dark-mode .core-bg-primary {
  background-color: #252c3e !important;
}
@media screen and (max-width: 600px) {
  .core-dark-mode .core-bg-primary {
    background-color: #202633 !important;
  }
}
.core-dark-mode .core-bg-secondary {
  background-color: #202633 !important;
}
.core-dark-mode .core-bg-accent-0 {
  background-color: #2b3347;
}
@media screen and (min-width: 1023px) {
  .core-dark-mode .core-bg-accent-0 {
    background-color: #2b3347 !important;
  }
}
.core-dark-mode .core-text-primary {
  color: #ffffff;
}
.core-dark-mode .core-text-secondary {
  color: #8890a3;
}
.core-dark-mode .core-index .core-text-secondary {
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .core-dark-mode .core-left-drawer .core-bg-secondary {
    background-color: #2b3347 !important;
  }
}
.core-dark-mode .core-right-drawer .core-bg-accent-1 {
  background-color: #333c52;
}
.core-dark-mode .core-right-drawer .core-bg-accent-1 .core-bg-accent-1 {
  background-color: #8890a3;
}
.core-dark-mode .core-right-drawer .core-analog-clock-accent-1 {
  filter: drop-shadow(5px 5px 3px #333c52) !important;
}
.core-dark-mode .core-footer .core-bg-white {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .core-dark-mode .core-footer .core-bg-white {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
.core-dark-mode .core-footer .core-text-violet-0 {
  color: #641fff;
}

.core-light-mode .core-bg-primary {
  background-color: #f5f5f5 !important;
}
.core-light-mode .core-bg-secondary {
  background-color: #ffffff !important;
}
.core-light-mode .core-bg-accent-0 {
  background-color: #ffffff;
}
@media screen and (min-width: 1023px) {
  .core-light-mode .core-bg-accent-0 {
    background-color: #ffffff !important;
  }
}
.core-light-mode .core-text-primary,
.core-light-mode .core-text-secondary {
  color: #202633;
}
@media screen and (max-width: 767px) {
  .core-light-mode .core-left-drawer .core-text-violet-1 {
    color: #651fff;
  }
}
.core-light-mode .core-right-drawer .core-bg-accent-1 {
  background-color: #ffffff;
}
.core-light-mode .core-right-drawer .core-bg-accent-1 .core-bg-accent-1 {
  background-color: #333c52;
}
.core-light-mode .core-right-drawer .core-analog-clock-accent-1 {
  filter: drop-shadow(5px 5px 3px #8890a3) !important;
}
.core-light-mode .core-footer .core-bg-white {
  background-color: #641fff;
}
@media screen and (min-width: 768px) {
  .core-light-mode .core-footer .core-bg-white {
    background-color: rgba(100, 31, 255, 0.7);
  }
}
.core-light-mode .core-footer .core-text-violet-0 {
  color: #ffffff;
}

.bg-accent-0 {
  background-color: #2b3347;
}

.bg-accent-1 {
  background-color: #333c52;
}

.bg-accent-2 {
  background-color: #8890a3;
}

.bg-accent-3 {
  background-color: #a7a7a7;
}

.bg-violet-0 {
  background-color: #6200ea;
}

.bg-violet-1 {
  background-color: #651fff;
}

.bg-violet-2 {
  background-color: #7c4dff;
}

.bg-violet-3 {
  background-color: #b388ff;
}

.bg-violet-4 {
  background-color: #d6bcfa;
}

.bg-violet-5 {
  background-color: #e9d8fd;
}

.bg-hover-accent-0 {
  transition: 0.2s ease-out;
}
.bg-hover-accent-0:hover {
  color: #ffffff;
  background-color: #2b3347;
}

.bg-hover-accent-1 {
  transition: 0.2s ease-out;
}
.bg-hover-accent-1:hover {
  color: #ffffff;
  background-color: #333c52;
}

.bg-hover-accent-2 {
  transition: 0.2s ease-out;
}
.bg-hover-accent-2:hover {
  color: #ffffff;
  background-color: #8890a3;
}

.bg-hover-accent-3 {
  transition: 0.2s ease-out;
}
.bg-hover-accent-3:hover {
  color: #ffffff;
  background-color: #a7a7a7;
}

.bg-hover-violet-0 {
  transition: 0.2s ease-out;
}
.bg-hover-violet-0:hover {
  color: #ffffff !important;
  background-color: #6200ea;
}

.bg-hover-violet-1 {
  transition: 0.2s ease-out;
}
.bg-hover-violet-1:hover {
  color: #ffffff !important;
  background-color: #651fff;
}

.bg-hover-violet-2 {
  transition: 0.2s ease-out;
}
.bg-hover-violet-2:hover {
  color: #ffffff !important;
  background-color: #7c4dff;
}

.bg-hover-violet-3 {
  transition: 0.2s ease-out;
}
.bg-hover-violet-3:hover {
  color: #ffffff !important;
  background-color: #b388ff;
}

.bg-hover-violet-4 {
  transition: 0.2s ease-out;
}
.bg-hover-violet-4:hover {
  color: #ffffff !important;
  background-color: #d6bcfa;
}

.bg-hover-violet-5 {
  transition: 0.2s ease-out;
}
.bg-hover-violet-5:hover {
  color: #ffffff !important;
  background-color: #e9d8fd;
}

.text-accent-0 {
  color: #2b3347;
}

.text-hover-accent-0 {
  transition: 0.2s ease-out;
}
.text-hover-accent-0:hover {
  color: #2b3347;
}

.text-accent-1 {
  color: #333c52;
}

.text-hover-accent-1 {
  transition: 0.2s ease-out;
}
.text-hover-accent-1:hover {
  color: #333c52;
}

.text-accent-2 {
  color: #8890a3;
}

.text-hover-accent-2 {
  transition: 0.2s ease-out;
}
.text-hover-accent-2:hover {
  color: #8890a3;
}

.text-accent-3 {
  color: #a7a7a7;
}

.text-hover-accent-3 {
  transition: 0.2s ease-out;
}
.text-hover-accent-3:hover {
  color: #a7a7a7;
}

.text-violet-0 {
  color: #6200ea;
}

.text-hover-violet-0 {
  transition: 0.2s ease-out;
}
.text-hover-violet-0:hover {
  color: #6200ea;
}

.text-violet-1 {
  color: #651fff;
}

.text-hover-violet-1 {
  transition: 0.2s ease-out;
}
.text-hover-violet-1:hover {
  color: #651fff;
}

.text-violet-2 {
  color: #7c4dff;
}

.text-hover-violet-2 {
  transition: 0.2s ease-out;
}
.text-hover-violet-2:hover {
  color: #7c4dff;
}

.text-violet-3 {
  color: #b388ff;
}

.text-hover-violet-3 {
  transition: 0.2s ease-out;
}
.text-hover-violet-3:hover {
  color: #b388ff;
}

.text-violet-4 {
  color: #d6bcfa;
}

.text-hover-violet-4 {
  transition: 0.2s ease-out;
}
.text-hover-violet-4:hover {
  color: #d6bcfa;
}

.text-violet-5 {
  color: #e9d8fd;
}

.text-hover-violet-5 {
  transition: 0.2s ease-out;
}
.text-hover-violet-5:hover {
  color: #e9d8fd;
}

/* source-sans-pro-300 - latin */
/* source-sans-pro-200 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-200.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro ExtraLight"), local("SourceSansPro-ExtraLight"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-200.eot?#iefix") format("embedded-opentype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-200.woff2") format("woff2"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-200.woff") format("woff"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-200.ttf") format("truetype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-200.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-300 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-300.eot?#iefix") format("embedded-opentype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-300.woff2") format("woff2"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-300.woff") format("woff"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-300.ttf") format("truetype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-300.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-regular.woff2") format("woff2"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-regular.woff") format("woff"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-regular.ttf") format("truetype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-regular.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-600.woff2") format("woff2"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-600.woff") format("woff"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-600.ttf") format("truetype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-600.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-700.woff2") format("woff2"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-700.woff") format("woff"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-700.ttf") format("truetype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-700.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-900.eot?#iefix") format("embedded-opentype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-900.woff2") format("woff2"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-900.woff") format("woff"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-900.ttf") format("truetype"), url("./../core/css/fonts/source-sans-pro/source-sans-pro-v13-latin-900.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
* {
  font-family: "Source Sans Pro";
}

@font-face {
  font-family: "Roboto";
  src: url("./../core/css/fonts/roboto/Roboto-Regular.eot");
  src: url("./../core/css/fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("./../core/css/fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("./../core/css/fonts/roboto/Roboto-Regular.woff") format("woff"), url("./../core/css/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/**
  Core Customed Text Sizes
*/
@media screen and (min-width: 768px) {
  .text-60 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1023px) {
  .text-60 {
    font-size: 40px;
  }
}
@media screen and (min-width: 1919px) {
  .text-60 {
    font-size: 60px;
  }
}
@media screen and (min-width: 5120px) {
  .text-60 {
    font-size: 64px;
  }
}

.text-33 {
  font-size: 28px;
}
@media screen and (min-width: 1919px) {
  .text-33 {
    font-size: 33px;
  }
}
@media screen and (min-width: 5120px) {
  .text-33 {
    font-size: 37px;
  }
}

.text-32 {
  font-size: 20px;
}
@media screen and (min-width: 768px) {
  .text-32 {
    font-size: 27px;
  }
}
@media screen and (min-width: 1919px) {
  .text-32 {
    font-size: 32px;
  }
}
@media screen and (min-width: 5120px) {
  .text-32 {
    font-size: 36px;
  }
}

.text-30 {
  font-size: 30px;
}
@media screen and (min-width: 5120px) {
  .text-30 {
    font-size: 34px;
  }
}

.text-28 {
  font-size: 23px;
}
@media screen and (min-width: 1919px) {
  .text-28 {
    font-size: 28px;
  }
}
@media screen and (min-width: 5120px) {
  .text-28 {
    font-size: 32px;
  }
}

.text-26 {
  font-size: 20px;
}
@media screen and (min-width: 1919px) {
  .text-26 {
    font-size: 26px;
  }
}
@media screen and (min-width: 5120px) {
  .text-26 {
    font-size: 30px;
  }
}

.text-24 {
  font-size: 24px;
}
@media screen and (min-width: 1023px) and (max-width: 2000px) {
  .text-24 {
    font-size: 18px;
  }
}

.text-23 {
  font-size: 18px;
}
@media screen and (min-width: 1919px) {
  .text-23 {
    font-size: 23px;
  }
}
@media screen and (min-width: 5120px) {
  .text-23 {
    font-size: 27px;
  }
}

.text-22 {
  font-size: 17px;
}
@media screen and (min-width: 1919px) {
  .text-22 {
    font-size: 22px;
  }
}
@media screen and (min-width: 5120px) {
  .text-22 {
    font-size: 26px;
  }
}

.text-20 {
  font-size: 16px;
}
@media screen and (min-width: 1919px) {
  .text-20 {
    font-size: 20px;
  }
}
@media screen and (min-width: 5120px) {
  .text-20 {
    font-size: 24px;
  }
}

.text-18 {
  font-size: 14px;
}
@media screen and (min-width: 1919px) {
  .text-18 {
    font-size: 18px;
  }
}
@media screen and (min-width: 5120px) {
  .text-18 {
    font-size: 22px;
  }
}

.text-16 {
  font-size: 11px;
}
@media screen and (min-width: 1919px) {
  .text-16 {
    font-size: 16px;
  }
}
@media screen and (min-width: 5120px) {
  .text-16 {
    font-size: 20px;
  }
}

.text-15 {
  font-size: 15px;
}

.text-14 {
  font-size: 14px;
}

.text-13 {
  font-size: 13px;
}

.text-12 {
  font-size: 12px;
}

.text-10 {
  font-size: 10px;
}

.text-9 {
  font-size: 9px;
}

.text-8 {
  font-size: 8px;
}

.text-bold {
  font-weight: 700;
}

.text-semibold {
  font-weight: 600;
}

/**
  Core Customed Borders/ Colors,Radius
*/
.border-radius-50 {
  border-radius: 50px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-accent-0 {
  border: 1px solid;
  border-color: #2b3347;
}

.border-accent-1 {
  border: 1px solid;
  border-color: #333c52;
}

.border-accent-2 {
  border: 1px solid;
  border-color: #8890a3;
}

.border-accent-3 {
  border: 1px solid;
  border-color: #a7a7a7;
}

.border-violet-0 {
  border: 1px solid;
  border-color: #6200ea;
}

.border-violet-1 {
  border: 1px solid;
  border-color: #651fff;
}

.border-violet-2 {
  border: 1px solid;
  border-color: #7c4dff;
}

.border-violet-3 {
  border: 1px solid;
  border-color: #b388ff;
}

.border-violet-4 {
  border: 1px solid;
  border-color: #d6bcfa;
}

.border-violet-5 {
  border: 1px solid;
  border-color: #e9d8fd;
}

.border.one {
  border: 1px solid;
}
.border.two {
  border: 2px solid;
}
.border.three {
  border: 3px solid;
}
.border.four {
  border: 4px solid;
}
.border.five {
  border: 5px solid;
}
.border.six {
  border: 6px solid;
}
.border.seven {
  border: 7px solid;
}
.border.eight {
  border: 8px solid;
}
.border.nine {
  border: 9px solid;
}
.border.ten {
  border: 10px solid;
}

/**
  Core global styles

  To avoid redundant styling all classes that can be use
  globally will be declared here.
*/
@media screen and (max-width: 767px) {
  .core-menu.side {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .core-menu.side {
    background: transparent;
  }
}
@media screen and (min-width: 1023px) {
  .core-menu.side {
    padding: 20px;
  }
}
@media screen and (min-width: 768px) {
  .core-menu.side .content .title {
    padding: 30px 0 20px 0;
  }
}
@media screen and (min-width: 1023px) {
  .core-menu.side .content .title {
    padding: 10px 0 20px 0;
  }
}
.core-menu.side .content .subtitle div {
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .core-menu.side .content .subtitle div {
    padding-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
  }
}
@media screen and (min-width: 1023px) {
  .core-menu.side .content .subtitle div {
    padding-bottom: 15px;
    display: block;
  }
}
.core-menu.side .content .subtitle div:hover {
  transition: 0.2s ease-out;
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .core-menu.main {
    padding: 20px;
    margin-top: 15px;
  }
}
@media screen and (min-width: 1023px) {
  .core-menu.main {
    margin-top: 0;
    margin-left: 15px;
  }
}
.core-menu.main .title {
  padding-top: 7px;
}
@media screen and (max-width: 767px) {
  .core-menu.main .title {
    text-align: center;
  }
}
.core-menu.side, .core-menu.main {
  border-radius: 15px;
}
@media screen and (min-width: 768px) {
  .core-menu.side, .core-menu.main {
    border-radius: 15px 15px 0 0;
  }
}

.core-select-field .q-field__label {
  margin-left: 15px;
  color: #202633;
  transform: none !important;
}
.core-select-field .q-field__control-container {
  padding-top: 0 !important;
}
.core-select-field span {
  margin-left: 15px;
}
.core-select-field .q-select__dropdown-icon {
  margin-right: 10px;
  color: #a7a7a7;
}
.core-select-field.height-31 {
  height: 31px;
}
.core-select-field.height-31 .q-field__marginal, .core-select-field.height-31 .q-field__control, .core-select-field.height-31 .q-field__native {
  height: 31px;
  min-height: 31px;
}
.core-select-field.height-31 .q-field__label {
  top: 5px;
}
.core-select-field.min-width-90 {
  min-width: 90px;
}
.core-select-field.min-width-90 .q-field__label, .core-select-field.min-width-90 span {
  padding-left: 20px;
}
.core-select-field.min-width-90 .q-field__append {
  padding-left: 0;
}
.core-select-field.use-input .q-field__input {
  padding-left: 15px;
}
.core-select-field.use-input ::placeholder {
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .q-select__dialog {
    background: transparent;
  }
  .q-select__dialog .q-field__inner {
    display: none;
  }
}

.option-style-dark,
.option-style-light {
  border-radius: 10px;
  border: 1px solid #7c4dff;
}
.option-style-dark .q-item--active,
.option-style-light .q-item--active {
  color: inherit;
}
@media screen and (min-width: 768px) {
  .option-style-dark,
.option-style-light {
    margin-top: 10px !important;
  }
}
.option-style-dark .q-item,
.option-style-light .q-item {
  min-height: 35px;
}
.option-style-dark.max-width-130,
.option-style-light.max-width-130 {
  max-width: 130px;
}
@media screen and (min-width: 1919px) {
  .option-style-dark.max-width-130,
.option-style-light.max-width-130 {
    max-width: 190px;
  }
}

.option-style-dark {
  background-color: #2b3347 !important;
  color: #ffffff;
}

.option-style-light {
  background-color: #f5f5f5 !important;
  color: #202633;
}

.selected-class {
  color: #ffffff;
  background-color: #2b3347;
}

.core-input-field.label-accent-2 .q-field__label {
  color: #8890a3 !important;
}
.core-input-field.label-accent-2 .q-field__native {
  color: #8890a3 !important;
}
.core-input-field .q-field__bottom {
  min-height: auto !important;
  padding: 0 !important;
}
.core-input-field .q-field__label {
  color: #ffffff;
  transform: none !important;
  font-size: 10px;
}
@media screen and (min-width: 1919px) {
  .core-input-field .q-field__label {
    font-size: 14px;
  }
}
@media screen and (min-width: 5120px) {
  .core-input-field .q-field__label {
    font-size: 18px;
  }
}
.core-input-field ::placeholder {
  opacity: 1;
}
.core-input-field .q-field__control {
  padding: 0 15px 15px;
}
.core-input-field.textarea .q-field__control-container {
  padding-top: 17px !important;
}

/**
  Button and q-menu style
*/
.q-btn.height-38 {
  height: 38px;
}

.core-q-menu {
  color: #ffffff;
  background: #2b3347;
}
.core-q-menu .q-item {
  min-height: 35px;
}

/*
* Table Style
*/
.core-table .q-table__top,
.core-table thead tr:first-child th {
  background-color: #5100c1;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  height: 56px !important;
}
.core-table tbody tr td {
  color: #8890a3;
  font-size: 14px;
  height: 40px !important;
}
.core-table tbody tr td button {
  border: none;
  background: none;
  color: #8890a3;
  text-decoration: underline;
  cursor: pointer;
}
.core-table .q-table__bottom {
  display: none;
}
.core-table thead .q-checkbox .q-checkbox__inner {
  color: #7c4dff !important;
}
.core-table tbody .q-checkbox .q-checkbox__inner {
  color: #6200ea !important;
}
.core-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.core-table tr {
  cursor: pointer;
}
.core-table tr .table-menu {
  width: 30px;
}
.core-table tr .table-menu .q-btn {
  transition: 0.3s ease-out;
  opacity: 0;
}
.core-table tr .table-menu .q-btn.active {
  background-color: red !important;
}
.core-table tr:hover .table-menu .q-btn {
  opacity: 1;
}

/*
* Pagination Style
*/
.paginationContainer {
  position: relative;
  width: 132px;
  height: 31px;
  border: 1px solid #7c4dff;
  border-radius: 19px;
}
.paginationContainer .pagination button:first-of-type {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #7c4dff;
  border-radius: 100%;
}
.paginationContainer .pagination button:last-of-type {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #7c4dff;
  border-radius: 100%;
}
.paginationContainer .pagination button:last-of-type,
.paginationContainer .pagination button:first-of-type {
  width: 25px;
}
.paginationContainer .pagination .justify-center button {
  display: none;
}
.paginationContainer .core-pagination-select-input {
  position: absolute;
  left: 53%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.paginationContainer .core-pagination-select-input .q-field__control {
  font-size: 15px;
  color: #202633;
  padding: 0px;
}
.paginationContainer .core-pagination-select-input .q-field__control::before, .paginationContainer .core-pagination-select-input .q-field__control::after {
  border: unset;
}
.paginationContainer .core-pagination-select-input .q-field__append {
  display: none;
}

.core-dark-mode .paginationContainer .core-pagination-select-input span {
  color: #ffffff;
}
.core-dark-mode .paginationContainer .core-pagination-select-input .q-field__control {
  color: #ffffff;
}

.select-pagination-light,
.select-pagination-dark {
  min-width: 70px !important;
  margin-left: -10px !important;
  border: 1px solid #7c4dff;
  border-radius: 10px;
}
.select-pagination-light .q-item,
.select-pagination-dark .q-item {
  min-height: 10px;
  font-size: 13px;
  padding: 0.61vw 11px;
}

.select-pagination-dark {
  background-color: #2b3347 !important;
  color: #ffffff;
}
.select-pagination-dark .q-item--active {
  color: #ffffff !important;
}

.select-pagination-light {
  background-color: #f5f5f5 !important;
  color: #202633;
}

/*
* Toggle button style
*/
.core-toggle .q-toggle__inner {
  border: 1px solid #707070;
  min-width: 35.66px;
  height: 21.79px;
  width: 35.66px;
  border-radius: 11px;
  padding: 3.1px 3.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.core-toggle .q-toggle__track {
  margin-top: 0;
  height: 15.56px;
  width: 100%;
  margin-left: 0;
  opacity: 1 !important;
  background: rgba(66, 76, 100, 0.5);
  border-radius: 11px;
}
.core-toggle .q-toggle__thumb {
  height: 13px;
  width: 13px;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
  left: 5px;
}
.core-toggle .q-toggle__thumb::after {
  box-shadow: none;
  background: #d1d1d1;
}
.core-toggle .q-toggle__thumb::before {
  opacity: 0 !important;
}
.core-toggle .q-toggle__inner--truthy .q-toggle__thumb {
  left: 15.7px !important;
}
.core-toggle .q-toggle__inner--truthy .q-toggle__track {
  background: rgba(124, 77, 255, 0.5);
}
.core-toggle .q-toggle__inner--indet .q-toggle__thumb {
  left: 5px;
}
.core-toggle .q-toggle__label {
  padding-left: 20px;
}

/**
* Core Loading Spinner
*/
.core-dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%, 100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}
.dialogContainer .dialogContent .q-field__native {
  color: #ffffff;
}
.dialogContainer .dialogContent .q-focus-helper {
  opacity: 0 !important;
}
@media screen and (max-width: 767px) {
  .dialogContainer .dialogContent .q-field__control-container,
.dialogContainer .dialogContent .q-field__inner {
    height: 31px !important;
  }
}

/**
Customed scroll bar position for core wrapper.
*/
@media screen and (min-width: 1023px) {
  .main-scroll .q-scrollarea__bar,
.main-scroll .q-scrollarea__thumb {
    right: 15px !important;
  }
}

.core-ip-management .mobile-view button i {
  font-size: 34.4px;
  margin-left: 17.7px !important;
}

.core-dark-mode .core-select-field span {
  color: #ffffff;
}
.core-dark-mode .core-select-field .q-field__label {
  color: #ffffff;
}
.core-dark-mode .core-select-field.use-input .q-field__input {
  color: #ffffff;
}
.core-dark-mode .core-input-field .q-field__native {
  color: #ffffff;
}
.core-dark-mode .core-ip-management .q-field__control .q-field__native {
  color: #ffffff !important;
}
.core-dark-mode .core-table tr:nth-child(even) {
  background-color: #333c52;
}
.core-dark-mode .core-table tr:nth-child(odd) {
  background-color: #2b3347;
}
.core-dark-mode .paginationContainer .pagination button {
  color: #ffffff !important;
}

/**
  Core dialog
*/
.core-dialog-background {
  background: url("src/core/assets/dialog-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.7);
}
.core-dialog-background .core-dialog-container {
  width: 100%;
  height: 400px;
  background: url("src/core/assets/dialog-container.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 314px;
  max-width: 100% !important;
}
@media screen and (min-width: 768px) {
  .core-dialog-background .core-dialog-container {
    width: 700px;
    height: 780px;
    background-size: 700px;
  }
}
@media screen and (min-width: 1024px) {
  .core-dialog-background .core-dialog-container {
    width: 500px;
    height: 500px;
    background-size: 450px;
  }
}
@media screen and (min-width: 1919px) {
  .core-dialog-background .core-dialog-container {
    width: 800px;
    height: 800px;
    background-size: 700px;
  }
}
.core-dialog-background .core-dialog-container .text-32 {
  font-size: 20px;
}
@media screen and (min-width: 768px) {
  .core-dialog-background .core-dialog-container .text-32 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .core-dialog-background .core-dialog-container .text-32 {
    font-size: 25px;
  }
}
.core-dialog-background .core-dialog-container .text-24 {
  font-size: 13px;
}
@media screen and (min-width: 768px) {
  .core-dialog-background .core-dialog-container .text-24 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .core-dialog-background .core-dialog-container .text-24 {
    font-size: 18px;
  }
}
.core-dialog-background .core-dialog-container .width-300 {
  width: 200px;
}
@media screen and (min-width: 768px) {
  .core-dialog-background .core-dialog-container .width-300 {
    width: 300px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .core-dialog-background .core-dialog-container .width-300 {
    width: 250px;
  }
}

.core-avatar-card .q-avatar {
  margin-top: 17px;
  margin-left: 10px;
}
.core-avatar-card .q-avatar .q-avatar__content.row.flex-center.overflow-hidden {
  border: 1px #641fff solid;
}
.core-avatar-card .avatar-log .log-status {
  display: flex;
  justify-content: right;
  padding-right: 33px;
}
.core-avatar-card .avatar-log .log-status .status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #6dd1a5;
  border: 1px solid rgba(112, 112, 112, 0.39);
}
.core-avatar-card .avatar-card-content {
  height: 94px;
  border-radius: 12px;
}
.core-avatar-card .action-button {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  height: 100%;
}
.core-avatar-card .action-button button {
  font-size: 8px;
  width: 47px;
}
.core-avatar-card .action-button button.bg-red {
  background: #ff7676 !important;
}
.core-avatar-card .action-button button.bg-primary {
  background: #3599be !important;
}
.core-avatar-card .action-button button.bg-secondary {
  background: #6dd1a5 !important;
}
.core-avatar-card .action-button .q-btn-group {
  border-radius: 0 12px 12px 0;
}
.core-avatar-card .fade-leave-active {
  transition: opacity 0.5s;
}
.core-avatar-card .fade-leave-to {
  opacity: 0;
}
.core-avatar-card .fade-enter-active {
  opacity: 0;
}
.core-avatar-card .fade-enter-to {
  opacity: 1;
  transition: opacity 0.5s;
}

/** Global CSS Overrides */
.juanhr {
  /***************************/
  /*****   GLOBA FORMAT   ****/
  /***************************/
  color: #43425d;
  font-size: 12px;
  /***************************/
  /********   TEXT   ********/
  /***************************/
  /***************************/
  /********   TABLE   ********/
  /***************************/
  /***************************/
  /*** LIST - juanhr-list ***/
  /**************************/
  /*********************/
  /*** BUTTONS ICONS ***/
  /*********************/
  /*********************/
  /**** ICONS FONTS ****/
  /*********************/
  /*************************************/
  /******* MODALS - juanhr-dialog ******/
  /*************************************/
  /******* MODALS - juanhr-modal ******/
  /** Popup Forms **/
  /************************************************/
  /******* TABLE SCROLLBAR - juanhr-table  ********/
  /************************************************/
  /************************************************/
  /******* POPUP FORMS - juanhr-form-wrapper ******/
  /************************************************/
  /************************************/
  /******* ALERTS - juanhr-alert ******/
  /************************************/
  /*******************/
  /******* TABS ******/
  /*******************/
  /***************************/
  /*******     FORMS    ******/
  /***************************/
  /*******    FIELDS    ******/
  /**************************/
  /*******    CARDS    ******/
  /**************************/
  /*****************************/
  /*******    MAIN BOX    ******/
  /*****************************/
  /*********************************/
  /*******   BRANCH MODULE    ******/
  /*********************************/
}
@media (max-width: 1023px) {
  .juanhr {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr {
    font-size: 15px;
  }
}
.juanhr .q-item__label {
  line-height: 1.1 !important;
}
.juanhr .q-tab-panel .q-item {
  padding: 0.85vw;
}
@media (max-width: 1023px) {
  .juanhr .q-tab-panel .q-item {
    padding: 1.12vw 1.35vw;
  }
}
.juanhr .text-subtitle2 {
  color: #43425d;
  font-size: 13px;
}
@media (max-width: 1023px) {
  .juanhr .text-subtitle2 {
    font-size: 10px;
  }
}
@media (min-width: 2000px) {
  .juanhr .text-subtitle2 {
    font-size: 16px;
  }
}
.juanhr .text-negative {
  color: #ff6565 !important;
}
.juanhr .q-field--error .q-field__bottom {
  color: #ff6565 !important;
}
.juanhr .q-table th {
  padding: 0.54vw 1.23vw;
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr .q-table th {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-table th {
    font-size: 15px;
  }
}
.juanhr .q-table tbody td {
  padding: 0.54vw 1.23vw;
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr .q-table tbody td {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-table tbody td {
    font-size: 15px;
  }
}
.juanhr-list {
  font-size: 14px;
  padding: 0.85vw;
  /** List Active **/
}
@media (max-width: 1023px) {
  .juanhr-list {
    font-size: 11px;
  }
}
@media (min-width: 2000px) {
  .juanhr-list {
    font-size: 18px;
  }
}
@media (max-width: 1023px) {
  .juanhr-list {
    padding: 1.35vw;
  }
}
.juanhr-list:hover {
  background: #fafafa;
  color: #f8a946;
  border-left: 0.23vw solid #f8a946;
  padding: 0.85vw 0.85vw 0.85vw 0.62vw !important;
}
@media (max-width: 1023px) {
  .juanhr-list:hover {
    border-left: 0.73vw solid #f8a946;
    padding: 1.35vw 1.35vw 1.35vw 1.12vw !important;
  }
}
.juanhr-list-active {
  background: #fafafa;
  color: #f8a946;
  border-left: 0.23vw solid #f8a946;
  padding: 0.85vw 0.85vw 0.85vw 0.62vw !important;
}
@media (max-width: 1023px) {
  .juanhr-list-active {
    border-left: 0.73vw solid #f8a946;
    padding: 1.35vw 1.35vw 1.35vw 1.12vw !important;
  }
}
.juanhr .q-btn {
  border-radius: 0.23vw;
  text-transform: capitalize;
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr .q-btn {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-btn {
    font-size: 15px;
  }
}
.juanhr .q-btn.q-btn--round {
  border-radius: 50% !important;
}
.juanhr .q-btn .q-btn__wrapper:before {
  box-shadow: none;
}
.juanhr .q-btn.long-button {
  min-width: 8vw;
}
.juanhr .q-btn.disabled .q-icon {
  color: #b5b5b5;
}
.juanhr .iconfont {
  font-size: 12px !important;
}
@media (max-width: 1023px) {
  .juanhr .iconfont {
    font-size: 9px !important;
  }
}
@media (min-width: 2000px) {
  .juanhr .iconfont {
    font-size: 16px !important;
  }
}
.juanhr .iconfont.juanhr-close {
  font-size: 10px;
}
.juanhr .iconfont.juanhr-carret-down, .juanhr .iconfont.juanhr-carret-up {
  margin-left: 0.5vw;
  margin-right: 0.2vw;
  font-size: 7px;
}
.juanhr .iconfont.juanhr-back {
  font-size: 15px !important;
}
.juanhr .iconfont.bigicon {
  font-size: 50px !important;
}
@media (max-width: 1023px) {
  .juanhr .iconfont.bigicon {
    font-size: 60px !important;
  }
}
@media (min-width: 2000px) {
  .juanhr .iconfont.bigicon {
    font-size: 120px !important;
  }
}
.juanhr-dialog .dialog-outer-box {
  width: 400px;
  padding: 20px;
  background: #374365;
}
.juanhr-dialog .dialog-inner-box {
  border-radius: 5px;
  padding: 20px 0;
  background: #fff;
}
.juanhr-dialog .bordered-circle {
  color: #ff6565;
  border: 6px solid #ff6565;
  border-radius: 50%;
}
.juanhr-dialog .middle-border {
  border: 2px solid #dddfe7;
  border-radius: 50%;
  width: 90px;
  padding: 8px;
  height: 90px;
}
.juanhr-dialog .outer-border {
  border: 1px solid #dddfe7;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: inline-flex;
  padding: 4px;
}
.juanhr-modal {
  color: #43425d;
  background-color: #142249;
  opacity: 0.98;
  border-radius: 1vw !important;
  max-width: 186vw !important;
  font-size: 16px;
  /** Uploader **/
}
@media (max-width: 1023px) {
  .juanhr-modal {
    font-size: 13px;
    max-width: 200vw !important;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal {
    font-size: 20px;
  }
}
.juanhr-modal .q_uploader__dnd {
  outline: 1px dashed #f8a946 !important;
  outline-offset: -10px !important;
  border-radius: 1vw;
}
.juanhr-modal .custom-uploader {
  width: 100%;
  border-radius: 0.23vw !important;
  text-transform: capitalize;
  max-height: none;
  padding: 1vw;
}
.juanhr-modal .custom-uploader .q-uploader__header {
  -webkit-order: 2;
  order: 2;
  background-color: transparent;
}
.juanhr-modal .custom-uploader .q-btn {
  min-width: 1vw !important;
}
.juanhr-modal .custom-uploader .q-uploader__list {
  background-color: #fff;
  -webkit-order: 1;
  order: 1;
  min-height: 10vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../resources/JuanHR/statics/Upload.png");
  background-size: 50%;
}
.juanhr-modal .custom-uploader .q-uploader__list .q-btn__wrapper {
  width: auto !important;
}
.juanhr-modal .custom-uploader .q-uploader__header-content {
  display: flex;
  flex-wrap: wrap;
}
.juanhr-modal .custom-uploader .q-uploader__header-content .q-btn {
  background-color: #f8a946;
  flex: 0 0 40%;
  max-width: 40%;
}
.juanhr-modal .custom-uploader .q-uploader__header-content > div {
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
}
.juanhr-modal .custom-uploader .q-uploader__header-content > div:nth-child(2) {
  -webkit-order: 1;
  order: 1;
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
}
.juanhr-modal .custom-uploader .q-uploader__header-content > a:nth-child(3) {
  -webkit-order: 2;
  order: 2;
  flex: 0 0 30%;
  max-width: 30%;
  background-color: #f8a946;
  display: block;
}
.juanhr-modal .custom-uploader .q-uploader__header-content > a:nth-child(1) {
  -webkit-order: 3;
  order: 3;
  flex: 0 0 30%;
  max-width: 30%;
  background-color: #ff6565;
  display: block;
}
.juanhr-modal .custom-uploader .q-uploader__header-content .col {
  text-align: center;
  width: 100%;
}
.juanhr-modal .custom-uploader .q-uploader__header-content .col .q-btn,
.juanhr-modal .custom-uploader .q-uploader__header-content .col .q-uploader__title,
.juanhr-modal .custom-uploader .q-uploader__header-content .col .q-uploader__subtitle {
  width: 100%;
  color: #43425d;
  text-align: center;
  word-break: normal;
  height: 1.7vw;
  font-size: 16px !important;
}
@media (max-width: 1023px) {
  .juanhr-modal .custom-uploader .q-uploader__header-content .col .q-btn,
.juanhr-modal .custom-uploader .q-uploader__header-content .col .q-uploader__title,
.juanhr-modal .custom-uploader .q-uploader__header-content .col .q-uploader__subtitle {
    font-size: 13px !important;
    height: 1.75vw;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal .custom-uploader .q-uploader__header-content .col .q-btn,
.juanhr-modal .custom-uploader .q-uploader__header-content .col .q-uploader__title,
.juanhr-modal .custom-uploader .q-uploader__header-content .col .q-uploader__subtitle {
    font-size: 20px !important;
  }
}
.juanhr-modal .bigicon {
  font-size: 50px !important;
}
@media (max-width: 1023px) {
  .juanhr-modal .bigicon {
    font-size: 60px !important;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal .bigicon {
    font-size: 120px !important;
  }
}
.juanhr-modal .bg-white {
  border-radius: 1vw;
}
.juanhr-modal .inner-section {
  min-width: 29vw;
}
@media (max-width: 1023px) {
  .juanhr-modal .inner-section {
    min-width: 40vw;
  }
}
.juanhr-modal .inner-section .q-btn {
  min-width: 8.5vw;
}
@media (min-width: 2000px) {
  .juanhr-modal .outer-border {
    margin: 1vw auto 1vw;
  }
}
.juanhr-modal .outer-border,
.juanhr-modal .middle-border {
  height: auto;
  width: auto;
}
.juanhr-modal .middle-border {
  padding: 0.62vw;
}
.juanhr-modal .text-subtitle1 {
  color: #43425d;
  font-size: 15px;
}
@media (max-width: 1023px) {
  .juanhr-modal .text-subtitle1 {
    font-size: 12px;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal .text-subtitle1 {
    font-size: 19px;
  }
}
.juanhr-modal .q-btn .q-icon {
  font-size: 10px;
}
@media (max-width: 1023px) {
  .juanhr-modal .q-btn .q-icon {
    font-size: 7px;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal .q-btn .q-icon {
    font-size: 14px;
  }
}
.juanhr-modal .q-btn {
  text-transform: capitalize;
  font-size: 15px;
}
@media (max-width: 1023px) {
  .juanhr-modal .q-btn {
    font-size: 12px;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal .q-btn {
    font-size: 19px;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal .q-py-sm {
    margin-bottom: 1vw;
  }
}
@media (max-width: 1919px) {
  .juanhr-modal .q-pa-lg {
    padding: 12px 24px 24px;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal .q-pa-lg {
    padding: 0.4vw 1.5vw 1.5vw;
  }
}
@media (min-width: 2000px) {
  .juanhr-modal .q-px-lg {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
}
.juanhr .q-toggle__inner {
  font-size: 13px;
  height: 26px;
  width: 3.2vw;
}
@media (max-width: 1023px) {
  .juanhr .q-toggle__inner {
    font-size: 10px;
    height: 20px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-toggle__inner {
    font-size: 17px;
    height: 30px;
  }
}
.juanhr .q-toggle__inner .q-toggle__track {
  border-radius: 1vw;
  height: 21px;
  background: transparent;
}
@media (max-width: 1023px) {
  .juanhr .q-toggle__inner .q-toggle__track {
    height: 10px;
  }
}
@media (min-width: 1919px) {
  .juanhr .q-toggle__inner .q-toggle__track {
    height: 28px;
  }
}
.juanhr .q-toggle__inner .q-toggle__thumb {
  width: 16px;
  height: 16px;
  top: 0.4vw;
}
@media (max-width: 1023px) {
  .juanhr .q-toggle__inner .q-toggle__thumb {
    width: 7px;
    height: 7px;
  }
}
@media (min-width: 1919px) {
  .juanhr .q-toggle__inner .q-toggle__thumb {
    top: 0.4vw;
    width: 16px;
    height: 16px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-toggle__inner .q-toggle__thumb {
    top: 0.4vw;
    width: 18px;
    height: 18px;
  }
}
.juanhr .q-toggle__inner.q-toggle__inner--truthy .q-toggle__track {
  border: 1px solid #fba22c;
}
.juanhr .q-toggle__inner.q-toggle__inner--falsy .q-toggle__track {
  border: 1px solid #9f9f9f;
}
.juanhr .q-toggle__inner.q-toggle__inner--truthy .q-toggle__thumb {
  left: 1.6vw;
}
.juanhr .q-toggle__inner.q-toggle__inner--falsy .q-toggle__thumb {
  left: 0.5vw;
}
.juanhr .q-toggle__inner.q-toggle__inner--falsy .q-toggle__thumb:after {
  background: #9f9f9f;
}
.juanhr .scroll-table ::-webkit-scrollbar {
  height: 8px;
  width: 10px;
}
.juanhr .scroll-table ::-webkit-scrollbar-track {
  background: rgba(236, 235, 238, 0.4);
}
.juanhr .scroll-table ::-webkit-scrollbar-thumb {
  background-color: #fba22c;
}
.juanhr .scroll-on-form {
  height: calc(100vh - 270px);
}
@media (min-width: 2000px) {
  .juanhr .scroll-on-form {
    height: calc(100vh - 273px);
  }
}
.juanhr-popup-container {
  height: 380px;
}
@media (min-width: 1919px) {
  .juanhr-popup-container {
    height: 470px;
  }
}
@media (min-width: 2000px) {
  .juanhr-popup-container {
    height: 500px;
  }
}
.juanhr-popup-container .absolute.full-height {
  right: 0;
  left: auto;
}
.juanhr-popup-container .scroll-on-form {
  height: 275px;
}
@media (min-width: 1919px) {
  .juanhr-popup-container .scroll-on-form {
    height: 365px;
  }
}
@media (min-width: 2000px) {
  .juanhr-popup-container .scroll-on-form {
    height: 395px;
  }
}
.juanhr-form-wrapper {
  height: 100%;
  position: relative;
  bottom: 0;
  z-index: 100;
  width: 100%;
  box-shadow: 0px 10px 6px 2px #c6c6c6;
  width: 300px;
}
@media (max-width: 1023px) {
  .juanhr-form-wrapper {
    width: 245px;
  }
}
@media (min-width: 1919px) {
  .juanhr-form-wrapper {
    width: 370px;
  }
}
@media (min-width: 2000px) {
  .juanhr-form-wrapper {
    width: 400px;
  }
}
.juanhr-form-wrapper .q-item {
  min-height: 3.2vw;
  padding: 0.61vw 1.23vw;
}
@media (max-width: 1023px) {
  .juanhr-form-wrapper .q-item {
    min-height: 3.7vw;
    padding: 1.11vw 1.73vw;
  }
}
.juanhr-form-wrapper .juanhr-form-header {
  background: #e8e8e8;
  border-top: 1px solid #e8e8e8;
  border-top-left-radius: 0.77vw;
  border-top-right-radius: 0.77vw;
  font-size: 13px;
}
@media (max-width: 1023px) {
  .juanhr-form-wrapper .juanhr-form-header {
    font-size: 10px;
  }
}
@media (min-width: 2000px) {
  .juanhr-form-wrapper .juanhr-form-header {
    font-size: 17px;
  }
}
.juanhr-form-wrapper .juanhr-form-content {
  background: #fafafa;
  height: 85%;
}
.juanhr-form-wrapper .juanhr-form-content p,
.juanhr-form-wrapper .juanhr-form-content .q-field--dense .q-field__bottom {
  font-size: 9px;
}
@media (max-width: 1023px) {
  .juanhr-form-wrapper .juanhr-form-content p,
.juanhr-form-wrapper .juanhr-form-content .q-field--dense .q-field__bottom {
    font-size: 6px;
  }
}
@media (min-width: 2000px) {
  .juanhr-form-wrapper .juanhr-form-content p,
.juanhr-form-wrapper .juanhr-form-content .q-field--dense .q-field__bottom {
    font-size: 13px;
  }
}
.juanhr-form-wrapper .juanhr-form-content .q-field__control-container {
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr-form-wrapper .juanhr-form-content .q-field__control-container {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr-form-wrapper .juanhr-form-content .q-field__control-container {
    font-size: 16px;
  }
}
.juanhr-form-wrapper .juanhr-form-content .q-btn {
  font-size: 10px;
}
@media (max-width: 1023px) {
  .juanhr-form-wrapper .juanhr-form-content .q-btn {
    font-size: 7px;
  }
}
@media (min-width: 2000px) {
  .juanhr-form-wrapper .juanhr-form-content .q-btn {
    font-size: 14px;
  }
}
.juanhr-alert {
  background-color: rgba(13, 26, 63, 0.95);
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr-alert {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr-alert {
    font-size: 16px;
  }
}
.juanhr-alert .q-btn {
  border-radius: 50%;
}
.juanhr-alert .iconfont.juanhr-close {
  font-size: 8px !important;
}
.juanhr-alert.fixed-width {
  width: 350px;
}
.juanhr-alert.simple {
  margin-bottom: 0;
  padding-bottom: 0;
}
.juanhr-alert .text-h6 {
  color: #ff6565;
}
.juanhr-alert .text {
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr-alert .text {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr-alert .text {
    font-size: 16px;
  }
}
.juanhr-alert .text.sub-color {
  color: #ff6565;
}
.juanhr-alert .message {
  color: #ff6565;
  float: left;
  margin-right: 0.5vw;
}
.juanhr .half-width {
  width: 46%;
}
.juanhr .badge-radius {
  border-radius: 2.31vw;
}
.juanhr .input-at-35 .q-field__inner .q-field__marginal,
.juanhr .input-at-35 .q-field__inner .q-field__control {
  height: 35px;
}
.juanhr .q-tabs__arrow {
  z-index: 500;
}
.juanhr .q-tabs {
  margin-bottom: 0px;
  z-index: 100 !important;
  position: relative;
}
.juanhr .q-tabs:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #d8d8d8;
  z-index: 100 !important;
  position: absolute;
  bottom: 0;
}
.juanhr .q-tabs .q-tab {
  min-height: 37px;
  padding: 0 1.5vw;
  border: 1px solid #d8d8d8;
  border-radius: 0.38vw 0.38vw 0 0;
  background-color: #f8f8f8;
  margin-right: 0.2vw;
  z-index: 50;
  margin-bottom: -1px;
}
.juanhr .q-tabs .q-tab.q-tab--active, .juanhr .q-tabs .q-tab[aria-selected=true] {
  border-bottom: 2px solid #fff;
  background: #fff !important;
  position: relative;
  z-index: 300 !important;
}
.juanhr .q-tabs .q-tab.q-tab--active .q-tab__label, .juanhr .q-tabs .q-tab[aria-selected=true] .q-tab__label {
  color: #43425d;
}
.juanhr .q-tabs .q-tab.q-tab--active:hover .q-focus-helper, .juanhr .q-tabs .q-tab[aria-selected=true]:hover .q-focus-helper {
  background: none;
}
.juanhr .q-tabs .q-tab.bordered-bottom {
  border-bottom: 2px solid #d3d3d3;
}
.juanhr .q-tabs .q-tab .q-tab__label {
  line-height: 1.2;
  font-weight: 500;
  color: #9e9e9e;
  font-size: 14px;
}
@media (max-width: 1023px) {
  .juanhr .q-tabs .q-tab .q-tab__label {
    font-size: 11px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-tabs .q-tab .q-tab__label {
    font-size: 18px;
  }
}
.juanhr form {
  caret-color: #f8a946;
}
.juanhr form small {
  color: #b5b5b5;
  font-size: 11px;
}
@media (max-width: 1023px) {
  .juanhr form small {
    font-size: 8px;
  }
}
@media (min-width: 2000px) {
  .juanhr form small {
    font-size: 15px;
  }
}
.juanhr form .q-field {
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr form .q-field {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr form .q-field {
    font-size: 16px;
  }
}
.juanhr form .q-btn {
  text-transform: capitalize;
  font-size: 13px;
}
@media (max-width: 1023px) {
  .juanhr form .q-btn {
    font-size: 10px;
  }
}
@media (min-width: 2000px) {
  .juanhr form .q-btn {
    font-size: 17px;
  }
}
.juanhr form .q-field__control-container {
  height: auto;
}
.juanhr form .q-field--focused .q-field__control:after {
  height: auto;
  box-shadow: 0px 0.08vw 0.23vw 0px #c6c6c6;
  border-width: 1px;
  border-radius: 0.23vw;
}
.juanhr form .q-field--outlined .q-field__control:hover:before {
  border-color: #f8a946;
  border-width: 1px;
  border-radius: 0.23vw;
}
.juanhr form .q-field--outlined .q-field__control:after {
  height: auto;
}
.juanhr .q-field--dense .q-field__control,
.juanhr .q-field--dense .q-field__marginal,
.juanhr .custom-outside-label .q-field--dense .q-field__control,
.juanhr .custom-small-placeholder .q-field--dense .q-field__control,
.juanhr .custom-small-placeholder .q-field--dense .q-field__marginal,
.juanhr .field--auto-height.q-field--dense .q-field__native,
.juanhr .q-field--auto-height.q-field--dense .q-field__control {
  max-height: none;
}
.juanhr .q-field--dense .q-field__control input,
.juanhr .q-field--dense .q-field__marginal input,
.juanhr .custom-outside-label .q-field--dense .q-field__control input,
.juanhr .custom-small-placeholder .q-field--dense .q-field__control input,
.juanhr .custom-small-placeholder .q-field--dense .q-field__marginal input,
.juanhr .field--auto-height.q-field--dense .q-field__native input,
.juanhr .q-field--auto-height.q-field--dense .q-field__control input {
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr .q-field--dense .q-field__control input,
.juanhr .q-field--dense .q-field__marginal input,
.juanhr .custom-outside-label .q-field--dense .q-field__control input,
.juanhr .custom-small-placeholder .q-field--dense .q-field__control input,
.juanhr .custom-small-placeholder .q-field--dense .q-field__marginal input,
.juanhr .field--auto-height.q-field--dense .q-field__native input,
.juanhr .q-field--auto-height.q-field--dense .q-field__control input {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-field--dense .q-field__control input,
.juanhr .q-field--dense .q-field__marginal input,
.juanhr .custom-outside-label .q-field--dense .q-field__control input,
.juanhr .custom-small-placeholder .q-field--dense .q-field__control input,
.juanhr .custom-small-placeholder .q-field--dense .q-field__marginal input,
.juanhr .field--auto-height.q-field--dense .q-field__native input,
.juanhr .q-field--auto-height.q-field--dense .q-field__control input {
    font-size: 16px;
  }
}
.juanhr .q-field {
  margin-top: 0.23vw;
}
.juanhr .q-field--auto-height .q-field__control,
.juanhr .q-field--auto-height .q-field__native {
  height: auto;
}
.juanhr .q-field__native,
.juanhr .q-field__prefix,
.juanhr .q-field__suffix,
.juanhr .q-field__input {
  color: #43425d;
}
.juanhr .q-field__native span,
.juanhr .q-field__prefix span,
.juanhr .q-field__suffix span,
.juanhr .q-field__input span {
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr .q-field__native span,
.juanhr .q-field__prefix span,
.juanhr .q-field__suffix span,
.juanhr .q-field__input span {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-field__native span,
.juanhr .q-field__prefix span,
.juanhr .q-field__suffix span,
.juanhr .q-field__input span {
    font-size: 16px;
  }
}
.juanhr .q-field--dense .q-field__label {
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr .q-field--dense .q-field__label {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr .q-field--dense .q-field__label {
    font-size: 16px;
  }
}
.juanhr .card-selected {
  background: #ebedf3 !important;
}
.juanhr .card-tile {
  background: #fafafa;
  box-shadow: 0px 0.08vw 0.23vw 0px #c6c6c6;
}
.juanhr .card-tile:hover {
  background: #ebedf3 !important;
  cursor: pointer;
}
.juanhr .card-tile .q-btn.half-width {
  margin: 1vw 1%;
  border-radius: 1.5vw;
  padding: 0.1vw;
  box-shadow: 2px 2px 4px 1px #c6c6c6;
  font-size: 11px;
}
@media (max-width: 1023px) {
  .juanhr .card-tile .q-btn.half-width {
    font-size: 8px;
  }
}
@media (min-width: 2000px) {
  .juanhr .card-tile .q-btn.half-width {
    font-size: 14px;
  }
}
.juanhr .card-tile .q-avatar {
  border-radius: 13px;
  width: 55px;
  height: 55px;
}
@media (max-width: 1023px) {
  .juanhr .card-tile .q-avatar {
    width: 52px;
    height: 52px;
    border-radius: 10px;
  }
}
@media (min-width: 2000px) {
  .juanhr .card-tile .q-avatar {
    width: 59px;
    height: 59px;
    border-radius: 17px;
  }
}
.juanhr .card-tile .q-avatar .q-img {
  width: 55px;
  height: 55px;
}
@media (max-width: 1023px) {
  .juanhr .card-tile .q-avatar .q-img {
    width: 52px;
    height: 52px;
  }
}
@media (min-width: 2000px) {
  .juanhr .card-tile .q-avatar .q-img {
    width: 59px;
    height: 59px;
  }
}
.juanhr .card-tile .text-weight-bold {
  font-weight: 600;
  font-size: 20px;
}
@media (max-width: 1023px) {
  .juanhr .card-tile .text-weight-bold {
    font-size: 17px;
  }
}
@media (min-width: 2000px) {
  .juanhr .card-tile .text-weight-bold {
    font-size: 24px;
  }
}
.juanhr .card-tile .q-badge {
  padding: 0.3vw 1vw;
  border-radius: 1.5vw;
  margin-bottom: 0.5vw;
  font-weight: 500;
  font-size: 11px;
}
@media (max-width: 1023px) {
  .juanhr .card-tile .q-badge {
    font-size: 8px;
  }
}
@media (min-width: 2000px) {
  .juanhr .card-tile .q-badge {
    font-size: 15px;
  }
}
.juanhr .card-tile .q-card__actions {
  font-size: 11px;
}
@media (max-width: 1023px) {
  .juanhr .card-tile .q-card__actions {
    font-size: 8px;
  }
}
@media (min-width: 2000px) {
  .juanhr .card-tile .q-card__actions {
    font-size: 15px;
  }
}
.juanhr .card-tile .icon-wrapper {
  border-radius: 50%;
  width: 1.15vw;
  height: 1.15vw;
  display: inline-block;
  margin-right: 0.38vw;
  text-align: center;
  background: #ff6565;
}
@media (min-width: 2000px) {
  .juanhr .card-tile .icon-wrapper {
    width: 1vw;
    height: 1vw;
  }
}
@media (max-width: 1023px) {
  .juanhr .card-tile .icon-wrapper {
    width: 1.65vw;
    height: 1.65vw;
  }
}
.juanhr .card-tile .icon-wrapper .q-icon {
  width: 1.15vw;
  height: 1.15vw;
}
@media (min-width: 2000px) {
  .juanhr .card-tile .icon-wrapper .q-icon {
    width: 1vw;
    height: 1vw;
  }
}
@media (max-width: 1023px) {
  .juanhr .card-tile .icon-wrapper .q-icon {
    width: 1.65vw;
    height: 1.65vw;
  }
}
.juanhr .card-tile .icon-wrapper .q-icon.iconfont {
  font-size: 9px;
}
.juanhr main {
  line-height: 1.2;
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr main {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr main {
    font-size: 16px;
  }
}
.juanhr main div.q-toolbar {
  min-height: 52px;
  line-height: 1.2;
  font-size: 13px;
}
@media (min-width: 2000px) {
  .juanhr main div.q-toolbar {
    font-size: 17px;
  }
}
@media (max-width: 1023px) {
  .juanhr main div.q-toolbar {
    font-size: 10px;
  }
}
.juanhr main div.q-toolbar .q-field__prepend .iconfont {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}
.juanhr main div.q-toolbar .q-field {
  margin-top: 0;
}
.juanhr main div.q-toolbar .search-input {
  width: 20vw;
}
@media (max-width: 1023px) {
  .juanhr main div.q-toolbar .search-input {
    width: 39vw;
  }
}
@media (min-width: 1500px) {
  .juanhr main div.q-toolbar .search-input {
    width: 20.7vw;
  }
}
@media (min-width: 1919px) {
  .juanhr main div.q-toolbar .search-input {
    width: 16.5vw;
  }
}
@media (min-width: 2000px) {
  .juanhr main div.q-toolbar .search-input {
    width: 18.5vw;
  }
}
.juanhr main div.q-toolbar .search-input .q-field__inner .q-field__control,
.juanhr main div.q-toolbar .search-input .q-field__inner .q-field__marginal {
  height: 35px;
}
.juanhr main div.q-toolbar .search-input .q-field__inner .q-field__control input,
.juanhr main div.q-toolbar .search-input .q-field__inner .q-field__marginal input {
  font-size: 12px;
}
.juanhr main div.q-toolbar .q-separator {
  margin: 0px 0px 0px 0.1vw;
}
.juanhr main div.q-toolbar .q-btn {
  margin: 0.1vw auto;
}
@media (min-width: 1500px) {
  .juanhr main div.q-toolbar .q-btn.q-btn-item {
    margin-left: 0.1vw;
  }
}
.juanhr main div.q-toolbar .absolute {
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr main div.q-toolbar .absolute {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr main div.q-toolbar .absolute {
    font-size: 16px;
  }
}
.juanhr main > div:nth-child(2) {
  line-height: 1.2;
  font-size: 12px;
}
@media (max-width: 1023px) {
  .juanhr main > div:nth-child(2) {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .juanhr main > div:nth-child(2) {
    font-size: 16px;
  }
}
.juanhr main > div:nth-child(2) div.q-toolbar {
  min-height: 52px;
}
.juanhr main > div:nth-child(2) div.q-toolbar .q-separator {
  margin: 0px 0.5vw;
}
.juanhr main > div:nth-child(2) div.q-toolbar .q-btn {
  margin: 0.1vw auto;
  max-height: 36px;
}
@media (max-width: 1023px) {
  .juanhr main > div:nth-child(2) div.q-toolbar .q-btn {
    max-height: 33px;
  }
}
@media (min-width: 2000px) {
  .juanhr main > div:nth-child(2) div.q-toolbar .q-btn {
    max-height: 45px;
  }
}
@media (min-width: 1500px) {
  .juanhr main > div:nth-child(2) div.q-toolbar .q-btn.q-btn-item {
    margin-left: 0.1vw;
  }
}
.juanhr main > div:nth-child(2) div.q-toolbar.toolbar-top .q-btn {
  margin-top: 7px;
}
@media (min-width: 2000px) {
  .juanhr main > div:nth-child(2) div.q-toolbar.toolbar-top .q-btn {
    margin-top: auto;
  }
}
.juanhr main .layout-header {
  min-height: 52px;
  height: 52px;
}
.juanhr main .layout-header button,
.juanhr main .layout-header .q-btn {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  height: 100%;
}
.juanhr .branch-header .q-field__control,
.juanhr .branch-header .q-field--dense .q-field__marginal {
  height: 35px;
}
.juanhr .branch-header input {
  font-size: 12px !important;
}
@media (max-width: 1023px) {
  .juanhr .department-list .checkbox-size .q-checkbox__inner {
    font-size: 30px;
  }
}

/********************************/
/*******   SCROLL PANEL    ******/
/********************************/
.scroll-panel-height {
  height: calc(100vh - 158px) !important;
}
@media (min-width: 2000px) {
  .scroll-panel-height {
    height: calc(100vh - 160px) !important;
  }
}
.scroll-panel-height-inner {
  height: calc(100vh - 211px) !important;
}
@media (min-width: 2000px) {
  .scroll-panel-height-inner {
    height: calc(100vh - 213px) !important;
  }
}
.scroll-panel-height-inner-tab {
  height: calc(100vh - 248px) !important;
}
@media (min-width: 1500px) {
  .scroll-panel-height-inner-tab {
    height: calc(100vh - 255px) !important;
  }
}
@media (min-width: 1919px) {
  .scroll-panel-height-inner-tab {
    height: calc(100vh - 265px) !important;
  }
}
@media (min-width: 2000px) {
  .scroll-panel-height-inner-tab {
    height: calc(100vh - 290px) !important;
  }
}
.scroll-panel-height-inner-tab-inside {
  height: calc(100vh - 326px) !important;
}
@media (min-width: 1919px) {
  .scroll-panel-height-inner-tab-inside {
    height: calc(100vh - 336px) !important;
  }
}
@media (min-width: 2000px) {
  .scroll-panel-height-inner-tab-inside {
    height: calc(100vh - 256px) !important;
  }
}
.scroll-panel-height-inner-tab-noheader {
  height: calc(100vh - 220px) !important;
}
@media (min-width: 1919px) {
  .scroll-panel-height-inner-tab-noheader {
    height: calc(100vh - 230px) !important;
  }
}
@media (min-width: 2000px) {
  .scroll-panel-height-inner-tab-noheader {
    height: calc(100vh - 250px) !important;
  }
}
.scroll-panel-height-inner-tab-withheader {
  height: calc(100vh - 260px) !important;
}
@media (min-width: 1500px) {
  .scroll-panel-height-inner-tab-withheader {
    height: calc(100vh - 265px) !important;
  }
}
@media (min-width: 1919px) {
  .scroll-panel-height-inner-tab-withheader {
    height: calc(100vh - 275px) !important;
  }
}
@media (min-width: 2000px) {
  .scroll-panel-height-inner-tab-withheader {
    height: calc(100vh - 300px) !important;
  }
}

@media (min-width: 2000px) {
  .col-xl-1p5 {
    height: auto;
    width: 12.5% !important;
  }
}

@media (min-width: 2000px) {
  .col-xl-10p5 {
    height: auto;
    width: 87.5% !important;
  }
}

/** Alert **/
.q-notifications__list--top.items-end {
  padding-top: 153px;
}
.q-notifications__list--top.items-end .juanhr-alert {
  margin-right: 60px;
}

/** GLOBAL VARIABLES **/
.btn-wide {
  min-width: 9.61vw;
  max-width: none;
}

.advanced-search-container .q-scrollarea {
  height: 300px;
}
.advanced-search-container small {
  color: #b5b5b5;
}
.advanced-search-container .q-field {
  margin-top: 0.23vw !important;
}

.auto-suggest {
  max-height: 300px;
  width: 100%;
  z-index: 1;
  top: calc(100% + 8px);
  border-bottom: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  font-size: 12px;
}
@media (max-width: 1023px) {
  .auto-suggest {
    font-size: 9px;
  }
}
@media (min-width: 2000px) {
  .auto-suggest {
    font-size: 16px;
  }
}
.auto-suggest svg {
  margin-right: 30px;
  font-size: 18px;
}
.auto-suggest .q-item {
  height: 52px;
  padding-left: 40px;
}
@media (min-width: 1919px) {
  .auto-suggest .q-item {
    padding-left: 45px;
  }
}
@media (min-width: 2000px) {
  .auto-suggest .q-item {
    padding-left: 45px;
  }
}
.auto-suggest .search-more {
  border-top: none;
}
.auto-suggest .search-more .q-item {
  padding-left: 12px;
}
.auto-suggest .search-more .q-item .icon-holder {
  border-radius: 50%;
  background: #142249;
  max-width: 20px;
  height: 20px;
  padding: 2px 4px;
  margin-top: 10px;
}
.auto-suggest .search-more .q-item .icon-holder img {
  height: 16px;
  width: 18px;
}

/*****************************/
/* Masterlists CSS Overrides */
/*****************************/
.round-checkbox .q-checkbox__bg {
  border-radius: 10px !important;
}

.border-circle {
  border-radius: 50% !important;
}

/*****************************/
/*********** FORMS ***********/
/*****************************/
/** Custom Outside Label */
.custom-outside-label .q-field__inner .q-field__label {
  top: -10px;
  left: -12px;
  padding-bottom: 20px;
}
.custom-outside-label .q-field__bottom {
  padding-left: 0;
  padding-right: 0;
}
.custom-outside-label .q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container,
.custom-outside-label .q-field__native {
  padding-top: 2px !important;
}
.custom-outside-label .q-field--dense .q-field__control {
  height: 35px;
}
.custom-outside-label .q-field--auto-height.q-field--dense .q-field__control {
  min-height: 35px;
}

/** This style make the error messages position to the right */
.error-message-right .q-field__messages {
  text-align: right;
}

/** Input 35 Height */
.input-sm .q-field--dense .q-field__control {
  height: 35px;
}
.input-sm .q-field--auto-height.q-field--dense .q-field__control {
  min-height: 35px;
}

.input-sm .q-field__inner .q-field__control,
.input-sm .q-field__inner .q-field__marginal {
  height: 36px;
}

/*****************************/
/******** PLACEHOLDER ********/
/*****************************/
.custom-small-placeholder .q-field--dense .q-field__label {
  top: 8px;
  font-size: 11px;
}
.custom-small-placeholder .q-field--dense .q-field__control {
  height: 35px;
}
.custom-small-placeholder .q-field--auto-height.q-field--dense .q-field__control {
  min-height: 35px;
}
.custom-small-placeholder .q-field--dense .q-field__control,
.custom-small-placeholder .q-field--dense .q-field__marginal {
  height: 35px;
}
.custom-small-placeholder .q-field--outlined .q-field__control:before {
  border: 1px solid rgba(236, 236, 236, 0.99);
}

/*****************************/
/********** COLORS ***********/
/*****************************/
.bg-violet {
  background: #43425d !important;
}
.bg-kahel {
  background: #f8a946 !important;
}
.bg-magenta {
  background: #ff6565 !important;
}

.text-violet {
  color: #43425d !important;
}
.text-kahel {
  color: #f8a946 !important;
}
.text-magenta {
  color: #ff6565 !important;
}

/*****************************/
/******** FORMATTING *********/
/*****************************/
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 50%;
}
.w-70 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 50%;
}
.w-90 {
  width: 50%;
}
.w-100 {
  width: 100%;
}

/*************************/
/******** ALERTS *********/
/*************************/
.q-position-engine {
  font-size: 11px;
}
@media (max-width: 1023px) {
  .q-position-engine {
    font-size: 8px;
  }
}
@media (min-width: 2000px) {
  .q-position-engine {
    font-size: 15px;
  }
}
.q-position-engine .q-item {
  padding: 0.61vw 1.23vw;
}
@media (max-width: 1023px) {
  .q-position-engine .q-item {
    padding: 1.11vw 1.73vw;
  }
}

/*****************************/
/****** TEXT OVERRIDES *******/
/*****************************/
.text-h5 {
  font-size: 20px;
}
@media (max-width: 1023px) {
  .text-h5 {
    font-size: 16px;
  }
}
@media (min-width: 2000px) {
  .text-h5 {
    font-size: 24px;
  }
}

.text-h6 {
  font-size: 16px;
}
@media (max-width: 1023px) {
  .text-h6 {
    font-size: 13px;
  }
}
@media (min-width: 2000px) {
  .text-h6 {
    font-size: 20px;
  }
}

.carousel__viewport {
  position: absolute;
  width: 100%;
}

.carousel .carousel__prev,
.carousel .carousel__next {
  visibility: hidden;
}

.dashBoardCalendar .q-date__main.col.column {
  flex-wrap: nowrap !important;
}

/** Import Juan HR Icons */
@font-face {
  font-family: "iconfont";
  /* Project id 1983055 */
  src: url("//at.alicdn.com/t/font_1983055_f2zwdhdd06s.woff2?t=1649483428445") format("woff2"), url("//at.alicdn.com/t/font_1983055_f2zwdhdd06s.woff?t=1649483428445") format("woff"), url("//at.alicdn.com/t/font_1983055_f2zwdhdd06s.ttf?t=1649483428445") format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.juanhr-organizationManagement:before {
  content: "";
}

.juanhr-personnel:before {
  content: "";
}

.juanhr-nacbia:before {
  content: "";
}

.juanhr-pwd:before {
  content: "";
}

.juanhr-settings:before {
  content: "";
}

.juanhr-logout:before {
  content: "";
}

.juanhr-f-about:before {
  content: "";
}

.juanhr-connectdevice:before {
  content: "";
}

.juanhr-user:before {
  content: "";
}

.juanhr-privacy:before {
  content: "";
}

.juanhr-person:before {
  content: "";
}

.juanhr-deals:before {
  content: "";
}

.juanhr-carret-right:before {
  content: "";
}

.juanhr-carret-left:before {
  content: "";
}

.juanhr-logs:before {
  content: "";
}

.juanhr-home-basket:before {
  content: "";
}

.juanhr-record:before {
  content: "";
}

.juanhr-item-sold-dashboard:before {
  content: "";
}

.juanhr-refund-dashboard:before {
  content: "";
}

.juanhr-sales-dashboard:before {
  content: "";
}

.juanhr-pricelist:before {
  content: "";
}

.juanhr-filter:before {
  content: "";
}

.juanhr-close-z:before {
  content: "";
}

.juanhr-printz:before {
  content: "";
}

.juanhr-posdailyreport:before {
  content: "";
}

.juanhr-posmonthlyreport:before {
  content: "";
}

.juanhr-printx:before {
  content: "";
}

.juanhr-transaction:before {
  content: "";
}

.juanhr-trending-up:before {
  content: "";
}

.juanhr-button-menu-round:before {
  content: "";
}

.juanhr-trending-down:before {
  content: "";
}

.juanhr-attendanceStatistics:before {
  content: "";
}

.juanhr-signout:before {
  content: "";
}

.juanhr-carret-up:before {
  content: "";
}

.juanhr-close:before {
  content: "";
}

.juanhr-carret-down:before {
  content: "";
}

.juanhr-backspace:before {
  content: "";
}

.juanhr-back:before {
  content: "";
}

.juanhr-minus:before {
  content: "";
}

.juanhr-sheet-close:before {
  content: "";
}

.juanhr-taxes:before {
  content: "";
}

.juanhr-tag:before {
  content: "";
}

.juanhr-tips:before {
  content: "";
}

.juanhr-cart:before {
  content: "";
}

.juanhr-history:before {
  content: "";
}

.juanhr-clipboard:before {
  content: "";
}

.juanhr-pie-graph:before {
  content: "";
}

.juanhr-employee-audit:before {
  content: "";
}

.juanhr-help:before {
  content: "";
}

.juanhr-sheet-check:before {
  content: "";
}

.juanhr-inventory:before {
  content: "";
}

.juanhr-schedule-time:before {
  content: "";
}

.juanhr-bar-graph:before {
  content: "";
}

.juanhr-button-menu:before {
  content: "";
}

.juanhr-calendar:before {
  content: "";
}

.juanhr-adjustment:before {
  content: "";
}

.juanhr-attendanceMonitoring:before {
  content: "";
}

.juanhr-thirteenthMonthPay:before {
  content: "";
}

.juanhr-allowance:before {
  content: "";
}

.juanhr-branchAssignment:before {
  content: "";
}

.juanhr-biometric:before {
  content: "";
}

.juanhr-bulk-download:before {
  content: "";
}

.juanhr-bulk-upload:before {
  content: "";
}

.juanhr-checkbox-active:before {
  content: "";
}

.juanhr-camera:before {
  content: "";
}

.juanhr-checkbox-inactive:before {
  content: "";
}

.juanhr-check-active:before {
  content: "";
}

.juanhr-check-inactive:before {
  content: "";
}

.juanhr-configuration:before {
  content: "";
}

.juanhr-employee:before {
  content: "";
}

.juanhr-exclude:before {
  content: "";
}

.juanhr-edit:before {
  content: "";
}

.juanhr-delete:before {
  content: "";
}

.juanhr-download:before {
  content: "";
}

.juanhr-dtr:before {
  content: "";
}

.juanhr-dashboard:before {
  content: "";
}

.juanhr-folder:before {
  content: "";
}

.juanhr-notification:before {
  content: "";
}

.juanhr-include:before {
  content: "";
}

.juanhr-loan:before {
  content: "";
}

.juanhr-payroll:before {
  content: "";
}

.juanhr-memoAndNotice:before {
  content: "";
}

.juanhr-overtimeAndHoliday:before {
  content: "";
}

.juanhr-leave:before {
  content: "";
}

.juanhr-masterlist:before {
  content: "";
}

.juanhr-question:before {
  content: "";
}

.juanhr-status-check:before {
  content: "";
}

.juanhr-search:before {
  content: "";
}

.juanhr-schedule:before {
  content: "";
}

.juanhr-serviceRecord:before {
  content: "";
}

.juanhr-upload:before {
  content: "";
}

.juanhr-benefit:before {
  content: "";
}

.juanhr-burger-menu:before {
  content: "";
}

.juanhr-warning:before {
  content: "";
}

.juanhr-information:before {
  content: "";
}

.juanhr-add:before {
  content: "";
}

.juanhr-store:before {
  content: "";
}

.juanhr-flag:before {
  content: "";
}

.juanhr-adjustmentlist1:before {
  content: "";
}

/** Import Juan HR Font Styles */
/* source-sans-pro-300 - latin */
/* source-sans-pro-200 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro ExtraLight"), local("SourceSansPro-ExtraLight"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.eot?#iefix") format("embedded-opentype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.woff2") format("woff2"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.woff") format("woff"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.ttf") format("truetype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-200.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-300 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.eot?#iefix") format("embedded-opentype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.woff2") format("woff2"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.woff") format("woff"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.ttf") format("truetype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-300.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.woff2") format("woff2"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.woff") format("woff"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.ttf") format("truetype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.woff2") format("woff2"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.woff") format("woff"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.ttf") format("truetype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-600.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.woff2") format("woff2"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.woff") format("woff"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.ttf") format("truetype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-700.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.eot?#iefix") format("embedded-opentype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.woff2") format("woff2"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.woff") format("woff"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.ttf") format("truetype"), url("../resources/JuanHR/css/fonts/source-sans-pro-v13-latin-900.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
* {
  font-family: "Source Sans Pro";
}