@font-face {
  font-family: "iconfont"; /* Project id 2799809 */
  src: url('//at.alicdn.com/t/font_2799809_iu84vk1ixe.woff2?t=1644633788566') format('woff2'),
       url('//at.alicdn.com/t/font_2799809_iu84vk1ixe.woff?t=1644633788566') format('woff'),
       url('//at.alicdn.com/t/font_2799809_iu84vk1ixe.ttf?t=1644633788566') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-local-activity:before {
  content: "\e670";
}

.icon-magnify:before {
  content: "\e66e";
}

.icon-activity:before {
  content: "\e66d";
}

.icon-dashboard:before {
  content: "\e66c";
}

.icon-add:before {
  content: "\e66b";
}

.icon-arrow-left:before {
  content: "\e66a";
}

.icon-search:before {
  content: "\e667";
}

.icon-burger:before {
  content: "\e664";
}

.icon-core:before {
  content: "\e660";
}

.icon-widgets:before {
  content: "\e653";
}

.icon-devices:before {
  content: "\e654";
}

.icon-open-person:before {
  content: "\e655";
}

.icon-hands-helping:before {
  content: "\e656";
}

.icon-box-close:before {
  content: "\e657";
}

.icon-question-answer:before {
  content: "\e658";
}

.icon-tablet:before {
  content: "\e659";
}

.icon-trash:before {
  content: "\e65a";
}

.icon-settings-antenna:before {
  content: "\e65b";
}

.icon-username:before {
  content: "\e65c";
}

.icon-desktop:before {
  content: "\e65d";
}

.icon-mobile:before {
  content: "\e65e";
}

.icon-user-lock:before {
  content: "\e65f";
}

.icon-history:before {
  content: "\e661";
}

.icon-network:before {
  content: "\e662";
}

.icon-pen:before {
  content: "\e663";
}

.icon-log-out:before {
  content: "\e665";
}

.icon-crispy-settings:before {
  content: "\e666";
}

.icon-laptop:before {
  content: "\e668";
}

.icon-metro-list:before {
  content: "\e669";
}

.icon-check-list:before {
  content: "\e639";
}

.icon-check-box:before {
  content: "\e63a";
}

.icon-close-circle:before {
  content: "\e63b";
}

.icon-fingerprint:before {
  content: "\e63c";
}

.icon-face-recognition:before {
  content: "\e63d";
}

.icon-help:before {
  content: "\e63e";
}

.icon-home:before {
  content: "\e63f";
}

.icon-lock:before {
  content: "\e640";
}

.icon-message:before {
  content: "\e641";
}

.icon-name:before {
  content: "\e642";
}

.icon-a-mobilephone:before {
  content: "\e643";
}

.icon-notification:before {
  content: "\e644";
}

.icon-phone:before {
  content: "\e645";
}

.icon-settings:before {
  content: "\e646";
}

.icon-user:before {
  content: "\e648";
}

.icon-arrow-down:before {
  content: "\e637";
}