/** Global CSS Overrides */
@import './global';

/** Import Juan HR Icons */
@import './juanhr.icons';

/** Import Juan HR Font Styles */
@import './source.sans.pro';


