.core-avatar-card {
	.q-avatar {
		margin-top: 17px;
		margin-left: 10px;
		.q-avatar__content.row.flex-center.overflow-hidden {
			border: 1px #641fff solid;
		}
	}

	.avatar-log {
		.log-status {
			display: flex;
			justify-content: right;
			padding-right: 33px;
			.status {
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background: #6dd1a5;
				border: 1px solid rgb(112 112 112 / 39%);
			}
		}
	}

	.avatar-card-content {
		height: 94px;
		border-radius: 12px;
	}

	.action-button {
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translateY(-50%);
		height: 100%;
		button {
			font-size: 8px;
			width: 47px;
			&.bg-red {
				background: #ff7676 !important;
			}
			&.bg-primary {
				background: #3599be !important;
			}
			&.bg-secondary {
				background: #6dd1a5 !important;
			}
		}
		.q-btn-group {
      border-radius: 0 12px 12px 0;
		}
	}

	.fade-leave-active {
		transition: opacity 0.5s;
	}
	.fade-leave-to {
		opacity: 0;
	}

	.fade-enter-active {
		opacity: 0;
	}

	.fade-enter-to {
		opacity: 1;
		transition: opacity 0.5s;
	}
}
