$breakpoint-xl-min: 2000px;
$breakpoint-sm-max: 1023px;
$breakpoint-lg-max: 1919px;
$breakpoint-lg-min: 1500px;
$q-color-kahel: #f8a946;
$q-color-violet: #43425d;
$q-color-magenta: #ff6565;

.juanhr {
	/***************************/
	/*****   GLOBA FORMAT   ****/
	/***************************/
	color: $q-color-violet;
	font-size: 12px;
	@media (max-width: $breakpoint-sm-max) {
		font-size: 9px;
	}
	@media (min-width: $breakpoint-xl-min) {
		font-size: 15px;
	}

	.q-item__label {
		line-height: 1.1 !important;
	}
	.q-tab-panel {
		.q-item {
			padding: 0.85vw;
			@media (max-width: $breakpoint-sm-max) {
				padding: 1.12vw 1.35vw;
			}
		}
	}

	.text-subtitle2 {
		color: $q-color-violet;
		font-size: 13px;
		@media (max-width: $breakpoint-sm-max) {
			font-size: 10px;
		}
		@media (min-width: $breakpoint-xl-min) {
			font-size: 16px;
		}
	}
	/***************************/
	/********   TEXT   ********/
	/***************************/

	.text-negative {
		color: $q-color-magenta !important;
	}
	.q-field--error .q-field__bottom {
		color: $q-color-magenta !important;
	}

	/***************************/
	/********   TABLE   ********/
	/***************************/
	.q-table {
		th {
			padding: 0.54vw 1.23vw;
			font-size: 12px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 9px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 15px;
			}
		}
		tbody td {
			padding: 0.54vw 1.23vw;
			font-size: 12px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 9px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 15px;
			}
		}
	}

	/***************************/
	/*** LIST - juanhr-list ***/
	/**************************/
	&-list {
		font-size: 14px;
		@media (max-width: $breakpoint-sm-max) {
			font-size: 11px;
		}
		@media (min-width: $breakpoint-xl-min) {
			font-size: 18px;
		}
		padding: 0.85vw;
		@media (max-width: $breakpoint-sm-max) {
			padding: 1.35vw;
		}

		&:hover {
			background: #fafafa;
			color: $q-color-kahel;
			border-left: 0.23vw solid $q-color-kahel;
			padding: 0.85vw 0.85vw 0.85vw 0.62vw !important;
			@media (max-width: $breakpoint-sm-max) {
				border-left: 0.73vw solid $q-color-kahel;
				padding: 1.35vw 1.35vw 1.35vw 1.12vw !important;
			}
		}

		/** List Active **/
		&-active {
			background: #fafafa;
			color: $q-color-kahel;
			border-left: 0.23vw solid $q-color-kahel;
			padding: 0.85vw 0.85vw 0.85vw 0.62vw !important;
			@media (max-width: $breakpoint-sm-max) {
				border-left: 0.73vw solid $q-color-kahel;
				padding: 1.35vw 1.35vw 1.35vw 1.12vw !important;
			}
		}
	}

	/*********************/
	/*** BUTTONS ICONS ***/
	/*********************/
	.q-btn {
		border-radius: 0.23vw;
		text-transform: capitalize;
		font-size: 12px;
		@media (max-width: $breakpoint-sm-max) {
			font-size: 9px;
		}
		@media (min-width: $breakpoint-xl-min) {
			font-size: 15px;
		}

		&.q-btn--round {
			border-radius: 50% !important;
		}
		.q-btn__wrapper:before {
			box-shadow: none;
		}
		&.long-button {
			min-width: 8vw;
		}
		&.disabled .q-icon {
			color: #b5b5b5;
		}
	}

	/*********************/
	/**** ICONS FONTS ****/
	/*********************/
	.iconfont {
		font-size: 12px !important;
    @media (max-width: $breakpoint-sm-max) {
      font-size: 9px !important;
    }
    @media (min-width: $breakpoint-xl-min) {
      font-size: 16px !important;
    }

		&.juanhr-close {
			font-size: 10px;
		}
		&.juanhr-carret-down,
		&.juanhr-carret-up {
			margin-left: 0.5vw;
			margin-right: 0.2vw;

			font-size: 7px;
		}

    &.juanhr-back {
      font-size: 15px !important;
    }

		&.bigicon {
			font-size: 50px !important;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 60px !important;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 120px !important;
			}
		}
	}

	/*************************************/
	/******* MODALS - juanhr-dialog ******/
	/*************************************/
	&-dialog {
		.dialog-outer-box {
			width: 400px;
			padding: 20px;
			background: #374365;
		}

		.dialog-inner-box {
			border-radius: 5px;
			padding: 20px 0;
			background: #fff;
		}

		.bordered-circle {
			color: $q-color-magenta;
			border: 6px solid $q-color-magenta;
			border-radius: 50%;
		}

		.middle-border {
			border: 2px solid #dddfe7;
			border-radius: 50%;
			width: 90px;
			padding: 8px;
			height: 90px;
		}

		.outer-border {
			border: 1px solid #dddfe7;
			border-radius: 50%;
			width: 100px;
			height: 100px;
			display: inline-flex;
			padding: 4px;
		}
	}

	/******* MODALS - juanhr-modal ******/
	&-modal {
		color: $q-color-violet;
		background-color: #142249;
		opacity: 0.98;
		border-radius: 1vw !important;
		max-width: 186vw !important;
		font-size: 16px;
		@media (max-width: $breakpoint-sm-max) {
			font-size: 13px;
			max-width: 200vw !important;
		}
		@media (min-width: $breakpoint-xl-min) {
			font-size: 20px;
		}

		/** Uploader **/
		.q_uploader__dnd {
			outline: 1px dashed $q-color-kahel !important;
			outline-offset: -10px !important;
			border-radius: 1vw;
		}

		.custom-uploader {
			width: 100%;
			border-radius: 0.23vw !important;
			text-transform: capitalize;
			max-height: none;
			padding: 1vw;

			.q-uploader__header {
				-webkit-order: 2;
				order: 2;
				background-color: transparent;
			}

			.q-btn {
				min-width: 1vw !important;
			}

			.q-uploader__list {
				//	display:none;
				background-color: #fff;
				-webkit-order: 1;
				order: 1;
				min-height: 10vw;
				background-repeat: no-repeat;
				background-position: center center;
				background-image: url('../resources/JuanHR/statics/Upload.png');
				background-size: 50%;
				.q-btn__wrapper {
					width: auto !important;
				}
			}

			.q-uploader__header-content {
				display: flex;
				flex-wrap: wrap;

				.q-btn {
					background-color: $q-color-kahel;
					flex: 0 0 40%;
					max-width: 40%;
				}
				& > div {
					flex: 0 0 100%;
					max-width: 100%;
					display: block;
				}
				& > div:nth-child(2) {
					-webkit-order: 1;
					order: 1;
					flex: 0 0 100%;
					max-width: 100%;
					display: block;
				}

				& > a:nth-child(3) {
					-webkit-order: 2;
					order: 2;
					flex: 0 0 30%;
					max-width: 30%;
					background-color: $q-color-kahel;
					display: block;
				}

				& > a:nth-child(1) {
					-webkit-order: 3;
					order: 3;
					flex: 0 0 30%;
					max-width: 30%;
					background-color: $q-color-magenta;
					display: block;
				}

				.col {
					text-align: center;
					width: 100%;
					.q-btn,
					.q-uploader__title,
					.q-uploader__subtitle {
						width: 100%;
						color: $q-color-violet;
						text-align: center;
						word-break: normal;
						height: 1.7vw;
						font-size: 16px !important;
						@media (max-width: $breakpoint-sm-max) {
							font-size: 13px !important;
							height: 1.75vw;
						}
						@media (min-width: $breakpoint-xl-min) {
							font-size: 20px !important;
						}
					}
				}
			}
		}

		.bigicon {
			font-size: 50px !important;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 60px !important;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 120px !important;
			}
		}

		.bg-white {
			border-radius: 1vw;
		}

		.inner-section {
			min-width: 29vw;
			@media (max-width: $breakpoint-sm-max) {
				min-width: 40vw;
			}
			.q-btn {
				min-width: 8.5vw;
			}
		}

		.outer-border {
			@media (min-width: $breakpoint-xl-min) {
				margin: 1vw auto 1vw;
			}
		}
		.outer-border,
		.middle-border {
			height: auto;
			width: auto;
		}
		.middle-border {
			padding: 0.62vw;
		}
		.text-subtitle1 {
			color: $q-color-violet;
			font-size: 15px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 12px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 19px;
			}
		}

		.q-btn .q-icon {
			font-size: 10px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 7px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 14px;
			}
		}

		.q-btn {
			text-transform: capitalize;
			font-size: 15px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 12px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 19px;
			}
		}

		.q-py-sm {
			@media (min-width: $breakpoint-xl-min) {
				margin-bottom: 1vw;
			}
		}

		.q-pa-lg {
			@media (max-width: $breakpoint-lg-max) {
				padding: 12px 24px 24px;
			}
			@media (min-width: $breakpoint-xl-min) {
				padding: 0.4vw 1.5vw 1.5vw;
			}
		}
		.q-px-lg {
			@media (min-width: $breakpoint-xl-min) {
				padding-left: 1.5vw;
				padding-right: 1.5vw;
			}
		}
	}

	/** Popup Forms **/
	.q-toggle__inner {
		font-size: 13px;
		height: 26px;
		@media (max-width: $breakpoint-sm-max) {
			font-size: 10px;
			height: 20px;
		}
		@media (min-width: $breakpoint-xl-min) {
			font-size: 17px;
			height: 30px;
		}
		width: 3.2vw;
		.q-toggle__track {
			border-radius: 1vw;
			height: 21px;
			background: transparent;
			@media (max-width: $breakpoint-sm-max) {
				height: 10px;
			}
			@media (min-width: $breakpoint-lg-max) {
				height: 28px;
			}
		}
		.q-toggle__thumb {
			width: 16px;
			height: 16px;
			top: 0.4vw;
			@media (max-width: $breakpoint-sm-max) {
				width: 7px;
				height: 7px;
			}
			@media (min-width: $breakpoint-lg-max) {
				top: 0.4vw;
				width: 16px;
				height: 16px;
			}
			@media (min-width: $breakpoint-xl-min) {
				top: 0.4vw;
				width: 18px;
				height: 18px;
			}
		}
		&.q-toggle__inner--truthy .q-toggle__track {
			border: 1px solid #fba22c;
		}

		&.q-toggle__inner--falsy .q-toggle__track {
			border: 1px solid #9f9f9f;
		}
		&.q-toggle__inner--truthy .q-toggle__thumb {
			left: 1.6vw;
		}
		&.q-toggle__inner--falsy .q-toggle__thumb {
			left: 0.5vw;
		}
		&.q-toggle__inner--falsy .q-toggle__thumb:after {
			background: #9f9f9f;
		}
	}

  /************************************************/
	/******* TABLE SCROLLBAR - juanhr-table  ********/
	/************************************************/

  .scroll-table {
    ::-webkit-scrollbar {
      height: 8px;
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(236, 235, 238, 0.4);
    }
    ::-webkit-scrollbar-thumb {
      background-color: #fba22c;
      // border-radius: 10px;
    }
  }

	/************************************************/
	/******* POPUP FORMS - juanhr-form-wrapper ******/
	/************************************************/

	.scroll-on-form {
		height: calc(100vh - 270px);
		@media (min-width: $breakpoint-xl-min) {
			height: calc(100vh - 273px);
		}
	}

	&-popup-container {
		height: 380px;
		@media (min-width: $breakpoint-lg-max) {
			height: 470px;
		}
		@media (min-width: $breakpoint-xl-min) {
			height: 500px;
		}
		.absolute.full-height {
			right: 0;
			left: auto;
		}

		.scroll-on-form {
			height: 275px;
			@media (min-width: $breakpoint-lg-max) {
				height: 365px;
			}
			@media (min-width: $breakpoint-xl-min) {
				height: 395px;
			}
		}
	}

	&-form-wrapper {
		height: 100%;
		position: relative;
		bottom: 0;
		z-index: 100;
		width: 100%;
		box-shadow: 0px 10px 6px 2px #c6c6c6;
		width: 300px;
		@media (max-width: $breakpoint-sm-max) {
			width: 245px;
		}
		@media (min-width: $breakpoint-lg-max) {
			width: 370px;
		}
		@media (min-width: $breakpoint-xl-min) {
			width: 400px;
		}
		.q-item {
			min-height: 3.2vw;
			padding: 0.61vw 1.23vw;

			@media (max-width: $breakpoint-sm-max) {
				min-height: 3.7vw;
				padding: 1.11vw 1.73vw;
			}
		}
		.juanhr-form-header {
			background: #e8e8e8;
			border-top: 1px solid #e8e8e8;
			border-top-left-radius: 0.77vw;
			border-top-right-radius: 0.77vw;
			font-size: 13px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 10px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 17px;
			}
		}

		.juanhr-form-content {
			background: #fafafa;
			height: 85%;
			p,
			.q-field--dense .q-field__bottom {
				font-size: 9px;
				@media (max-width: $breakpoint-sm-max) {
					font-size: 6px;
				}
				@media (min-width: $breakpoint-xl-min) {
					font-size: 13px;
				}
			}
			.q-field__control-container {
				font-size: 12px;
				@media (max-width: $breakpoint-sm-max) {
					font-size: 9px;
				}
				@media (min-width: $breakpoint-xl-min) {
					font-size: 16px;
				}
			}
			.q-btn {
				font-size: 10px;
				@media (max-width: $breakpoint-sm-max) {
					font-size: 7px;
				}
				@media (min-width: $breakpoint-xl-min) {
					font-size: 14px;
				}
			}
		}
	}

	/************************************/
	/******* ALERTS - juanhr-alert ******/
	/************************************/
	&-alert {
		background-color: rgba(13, 26, 63, 0.95);

		font-size: 12px;
		@media (max-width: $breakpoint-sm-max) {
			font-size: 9px;
		}
		@media (min-width: $breakpoint-xl-min) {
			font-size: 16px;
		}

		.q-btn {
			border-radius: 50%;
		}
		.iconfont {
			&.juanhr-close {
				font-size: 8px !important;
			}
		}
		&.fixed-width {
			width: 350px;
		}
		&.simple {
			margin-bottom: 0;
			padding-bottom: 0;
		}
		.text-h6 {
			color: $q-color-magenta;
		}
		.text {
			font-size: 12px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 9px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 16px;
			}
			&.sub-color {
				color: $q-color-magenta;
			}
		}
		.message {
			color: $q-color-magenta;
			float: left;
			margin-right: 0.5vw;
		}
	}

	.half-width {
		width: 46%;
	}

	.badge-radius {
		border-radius: 2.31vw;
	}

	.input-at-35 {
		.q-field__inner {
			.q-field__marginal,
			.q-field__control {
				height: 35px;
			}
		}
	}

	/*******************/
	/******* TABS ******/
	/*******************/
	.q-tabs__arrow {
		z-index: 500;
	}
	.q-tabs {
		margin-bottom: 0px;
		z-index: 100 !important;
		position: relative;
		&:after {
			content: '';
			width: 100%;
			height: 1px;
			background: #d8d8d8;
			z-index: 100 !important;
			position: absolute;
			bottom: 0;
		}
		.q-tab {
			min-height: 37px;
			padding: 0 1.5vw;
			border: 1px solid #d8d8d8;
			border-radius: 0.38vw 0.38vw 0 0;
			background-color: #f8f8f8;
			margin-right: 0.2vw;
			z-index: 50;
			margin-bottom: -1px;

			&.q-tab--active,
			&[aria-selected='true'] {
				border-bottom: 2px solid #fff;
				background: #fff !important;
				position: relative;
				z-index: 300 !important;
				.q-tab__label {
					//font-weight:700 !important;
					color: $q-color-violet;
				}
				&:hover .q-focus-helper {
					background: none;
				}
			}
      &.bordered-bottom {
        border-bottom: 2px solid #d3d3d3;;
      }
			.q-tab__label {
				line-height: 1.2;
				font-weight: 500;
				color: #9e9e9e;
				font-size: 14px;
				@media (max-width: $breakpoint-sm-max) {
					font-size: 11px;
				}
				@media (min-width: $breakpoint-xl-min) {
					font-size: 18px;
				}
			}
		}
	}

	/***************************/
	/*******     FORMS    ******/
	/***************************/
	form {
		caret-color: $q-color-kahel;
		small {
			color: #b5b5b5;
			font-size: 11px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 8px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 15px;
			}
		}
		.q-field {
			font-size: 12px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 9px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 16px;
			}
		}
		.q-btn {
			text-transform: capitalize;
			font-size: 13px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 10px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 17px;
			}
		}

		.q-field__control-container {
			height: auto;
		}
		.q-field--focused .q-field__control:after {
			height: auto;
			box-shadow: 0px 0.08vw 0.23vw 0px #c6c6c6;
			border-width: 1px;
			border-radius: 0.23vw;
		}
		.q-field--outlined .q-field__control:hover:before {
			border-color: $q-color-kahel;
			border-width: 1px;
			border-radius: 0.23vw;
		}
		.q-field--outlined .q-field__control:after {
			height: auto;
		}
	}

	/*******    FIELDS    ******/
	.q-field--dense .q-field__control,
	.q-field--dense .q-field__marginal,
	.custom-outside-label .q-field--dense .q-field__control,
	.custom-small-placeholder .q-field--dense .q-field__control,
	.custom-small-placeholder .q-field--dense .q-field__marginal,
	.field--auto-height.q-field--dense .q-field__native,
	.q-field--auto-height.q-field--dense .q-field__control {
		max-height: none;
		input {
			font-size: 12px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 9px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 16px;
			}
		}
	}

	.q-field {
		margin-top: 0.23vw;
	}

	.q-field--auto-height .q-field__control,
	.q-field--auto-height .q-field__native {
		height: auto;
	}

	.q-field__native,
	.q-field__prefix,
	.q-field__suffix,
	.q-field__input {
		color: $q-color-violet;
		span {
			font-size: 12px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 9px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 16px;
			}
		}
	}
	.q-field--dense .q-field__label {
		font-size: 12px;
		@media (max-width: $breakpoint-sm-max) {
			font-size: 9px;
		}
		@media (min-width: $breakpoint-xl-min) {
			font-size: 16px;
		}
	}

	/**************************/
	/*******    CARDS    ******/
	/**************************/
	.card-selected {
		background: #ebedf3 !important;
	}

	.card-tile {
		background: #fafafa;
		box-shadow: 0px 0.08vw 0.23vw 0px #c6c6c6;
		&:hover {
			background: #ebedf3 !important;
			cursor: pointer;
		}
		.q-btn.half-width {
			margin: 1vw 1%;
			border-radius: 1.5vw;
			padding: 0.1vw;
			box-shadow: 2px 2px 4px 1px #c6c6c6;
			font-size: 11px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 8px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 14px;
			}
		}

		.q-avatar {
			border-radius: 13px;
			width: 55px;
			height: 55px;
			@media (max-width: $breakpoint-sm-max) {
				width: 52px;
				height: 52px;
				border-radius: 10px;
			}
			@media (min-width: $breakpoint-xl-min) {
				width: 59px;
				height: 59px;
				border-radius: 17px;
			}
			.q-img {
				width: 55px;
				height: 55px;
				@media (max-width: $breakpoint-sm-max) {
					width: 52px;
					height: 52px;
				}
				@media (min-width: $breakpoint-xl-min) {
					width: 59px;
					height: 59px;
				}
			}
		}
		.text-weight-bold {
			font-weight: 600;
			font-size: 20px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 17px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 24px;
			}
		}

		.q-badge {
			padding: 0.3vw 1vw;
			border-radius: 1.5vw;
			margin-bottom: 0.5vw;
			font-weight: 500;
			font-size: 11px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 8px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 15px;
			}
		}
		.q-card__actions {
			font-size: 11px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 8px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 15px;
			}
		}
		.icon-wrapper {
			border-radius: 50%;
			width: 1.15vw;
			height: 1.15vw;
			display: inline-block;
			margin-right: 0.38vw;
			text-align: center;
			background: $q-color-magenta;

			@media (min-width: $breakpoint-xl-min) {
				width: 1vw;
				height: 1vw;
			}
			@media (max-width: $breakpoint-sm-max) {
				width: 1.65vw;
				height: 1.65vw;
			}

			.q-icon {
				width: 1.15vw;
				height: 1.15vw;
				@media (min-width: $breakpoint-xl-min) {
					width: 1vw;
					height: 1vw;
				}
				@media (max-width: $breakpoint-sm-max) {
					width: 1.65vw;
					height: 1.65vw;
				}
				&.iconfont {
					font-size: 9px;
				}
			}
		}
	}

	/*****************************/
	/*******    MAIN BOX    ******/
	/*****************************/
	main {
		line-height: 1.2;
		font-size: 12px;
		@media (max-width: $breakpoint-sm-max) {
			font-size: 9px;
		}
		@media (min-width: $breakpoint-xl-min) {
			font-size: 16px;
		}

		div.q-toolbar {
			min-height: 52px;
			line-height: 1.2;
			//padding: 0 1.23vw;
			font-size: 13px;

			@media (min-width: $breakpoint-xl-min) {
				font-size: 17px;
			}
			@media (max-width: $breakpoint-sm-max) {
				font-size: 10px;
			}

			.q-field__prepend .iconfont {
				margin-left: 0.5vw;
				margin-right: 0.5vw;
			}

			.q-field {
				margin-top: 0;
			}

			.search-input {
				width: 20vw;
				@media (max-width: $breakpoint-sm-max) {
					width: 39vw;
				}
				@media (min-width: $breakpoint-lg-min) {
					width: 20.7vw;
				}
				@media (min-width: $breakpoint-lg-max) {
					width: 16.5vw;
				}
				@media (min-width: $breakpoint-xl-min) {
					width: 18.5vw;
				}
				.q-field__inner {
					.q-field__control,
					.q-field__marginal {
						height: 35px;
						input {
							font-size: 12px;
						}
					}
				}
			}

			.q-separator {
				margin: 0px 0px 0px 0.1vw;
			}

			.q-btn {
				margin: 0.1vw auto;
				&.q-btn-item {
					@media (min-width: $breakpoint-lg-min) {
						margin-left: 0.1vw;
					}
				}
			}

			.absolute {
				font-size: 12px;
				@media (max-width: $breakpoint-sm-max) {
					font-size: 9px;
				}
				@media (min-width: $breakpoint-xl-min) {
					font-size: 16px;
				}
			}
		}

		& > div:nth-child(2) {
			line-height: 1.2;
			font-size: 12px;
			@media (max-width: $breakpoint-sm-max) {
				font-size: 9px;
			}
			@media (min-width: $breakpoint-xl-min) {
				font-size: 16px;
			}
			div.q-toolbar {
				min-height: 52px;
				.q-separator {
					margin: 0px 0.5vw;
				}

				.q-btn {
					margin: 0.1vw auto;
					max-height: 36px;
					@media (max-width: $breakpoint-sm-max) {
						max-height: 33px;
					}
					@media (min-width: $breakpoint-xl-min) {
						max-height: 45px;
					}
					&.q-btn-item {
						@media (min-width: $breakpoint-lg-min) {
							margin-left: 0.1vw;
						}
					}
				}
				&.toolbar-top {
					.q-btn {
						margin-top: 7px;
						@media (min-width: $breakpoint-xl-min) {
							margin-top: auto;
						}
					}
				}
			}
		}

		.layout-header {
			min-height: 52px;
			height: 52px;
			button,
			.q-btn {
				font-size: 13px;
				font-weight: 700;
				line-height: 1.2;
				height: 100%;
			}
		}
	}

	/*********************************/
	/*******   BRANCH MODULE    ******/
	/*********************************/
	.branch-header {
		.q-field__control,
		.q-field--dense .q-field__marginal {
			height: 35px;
		}
		input {
			font-size: 12px !important;
		}
	}

	.department-list {
		.checkbox-size {
			.q-checkbox__inner {
				@media (max-width: $breakpoint-sm-max) {
					font-size: 30px;
				}
			}
		}
	}
}

/********************************/
/*******   SCROLL PANEL    ******/
/********************************/
.scroll-panel-height {
	height: calc(100vh - 158px) !important;
	@media (min-width: $breakpoint-xl-min) {
		height: calc(100vh - 160px) !important;
	}

	&-inner {
		height: calc(100vh - 211px) !important;
		@media (min-width: $breakpoint-xl-min) {
			height: calc(100vh - 213px) !important;
		}

		&-tab {
			height: calc(100vh - 248px) !important;
			@media (min-width: $breakpoint-lg-min) {
				height: calc(100vh - 255px) !important;
			}
			@media (min-width: $breakpoint-lg-max) {
				height: calc(100vh - 265px) !important;
			}
			@media (min-width: $breakpoint-xl-min) {
				height: calc(100vh - 290px) !important;
			}

			&-inside {
				height: calc(100vh - 326px) !important;
				@media (min-width: $breakpoint-lg-max) {
					height: calc(100vh - 336px) !important;
				}
				@media (min-width: $breakpoint-xl-min) {
					height: calc(100vh - 256px) !important;
				}
			}

			&-noheader {
				height: calc(100vh - 220px) !important;
				@media (min-width: $breakpoint-lg-max) {
					height: calc(100vh - 230px) !important;
				}
				@media (min-width: $breakpoint-xl-min) {
					height: calc(100vh - 250px) !important;
				}
			}

			&-withheader {
				height: calc(100vh - 260px) !important;
				@media (min-width: $breakpoint-lg-min) {
					height: calc(100vh - 265px) !important;
				}
				@media (min-width: $breakpoint-lg-max) {
					height: calc(100vh - 275px) !important;
				}
				@media (min-width: $breakpoint-xl-min) {
					height: calc(100vh - 300px) !important;
				}
			}
		}
	}
}

.col-xl-1p5 {
	@media (min-width: $breakpoint-xl-min) {
		height: auto;
		width: 12.5% !important;
	}
}
.col-xl-10p5 {
	@media (min-width: $breakpoint-xl-min) {
		height: auto;
		width: 87.5% !important;
	}
}
/** Alert **/
.q-notifications__list--top.items-end {
	padding-top: 153px;
	.juanhr-alert {
		margin-right: 60px;
	}
}

/** GLOBAL VARIABLES **/
.btn-wide {
	min-width: 9.61vw;
	max-width: none;
}

.advanced-search-container {
	.q-scrollarea {
		height: 300px;
	}
	small {
		color: #b5b5b5;
	}

	.q-field {
		margin-top: 0.23vw !important;
	}
}

.auto-suggest {
	max-height: 300px;
	width: 100%;
	z-index: 1;
	top: calc(100% + 8px);
	border-bottom: 1px solid #e7e7e7;
	border-bottom: 1px solid #e7e7e7;
	font-size: 12px;
	@media (max-width: $breakpoint-sm-max) {
		font-size: 9px;
	}
	@media (min-width: $breakpoint-xl-min) {
		font-size: 16px;
	}

	svg {
		margin-right: 30px;
		font-size: 18px;
	}
	.q-item {
		height: 52px;
		padding-left: 40px;
		@media (min-width: $breakpoint-lg-max) {
			padding-left: 45px;
		}
		@media (min-width: $breakpoint-xl-min) {
			padding-left: 45px;
		}
	}

	.search-more {
		border-top: none;
		.q-item {
			padding-left: 12px;
			.icon-holder {
				border-radius: 50%;
				background: #142249;
				max-width: 20px;
				height: 20px;
				padding: 2px 4px;
				margin-top: 10px;
				img {
					height: 16px;
					width: 18px;
				}
			}
		}
	}
}

// FROM ERP-CORE
/*****************************/
/* Masterlists CSS Overrides */
/*****************************/
.round-checkbox {
	.q-checkbox__bg {
		border-radius: 10px !important;
	}
}
.border-circle {
	border-radius: 50% !important;
}

/*****************************/
/*********** FORMS ***********/
/*****************************/

/** Custom Outside Label */
.custom-outside-label {
	.q-field__inner .q-field__label {
		top: -10px;
		left: -12px;
		padding-bottom: 20px;
	}

	.q-field__bottom {
		padding-left: 0;
		padding-right: 0;
	}

	.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container,
	.q-field__native {
		padding-top: 2px !important;
	}

	// Input Height
	.q-field--dense .q-field__control {
		height: 35px;
	}

	.q-field--auto-height.q-field--dense .q-field__control {
		min-height: 35px;
	}
}

/** This style make the error messages position to the right */
.error-message-right .q-field__messages {
	text-align: right;
}

/** Input 35 Height */
.input-sm {
	// Input Height
	.q-field--dense .q-field__control {
		height: 35px;
	}

	.q-field--auto-height.q-field--dense .q-field__control {
		min-height: 35px;
	}
}
.input-sm {
	.q-field__inner {
		.q-field__control,
		.q-field__marginal {
			height: 36px;
		}
	}
}

/*****************************/
/******** PLACEHOLDER ********/
/*****************************/
.custom-small-placeholder {
	// Input Label
	.q-field--dense .q-field__label {
		top: 8px;
		font-size: 11px;
	}

	// Input Height
	.q-field--dense .q-field__control {
		height: 35px;
	}

	.q-field--auto-height.q-field--dense .q-field__control {
		min-height: 35px;
	}

	// Input icom
	.q-field--dense .q-field__control,
	.q-field--dense .q-field__marginal {
		height: 35px;
	}

	.q-field--outlined .q-field__control:before {
		border: 1px solid rgba(236, 236, 236, 0.99);
	}
}

/*****************************/
/********** COLORS ***********/
/*****************************/

.bg {
	&-violet {
		background: $q-color-violet !important;
	}

	&-kahel {
		background: $q-color-kahel !important;
	}

	&-magenta {
		background: $q-color-magenta !important;
	}
}

.text {
	&-violet {
		color: $q-color-violet !important;
	}
	&-kahel {
		color: $q-color-kahel !important;
	}
	&-magenta {
		color: $q-color-magenta !important;
	}
}

/*****************************/
/******** FORMATTING *********/
/*****************************/
.w {
	&-10 {
		width: 10%;
	}
	&-20 {
		width: 20%;
	}
	&-25 {
		width: 20%;
	}
	&-30 {
		width: 30%;
	}
	&-40 {
		width: 40%;
	}
	&-50 {
		width: 50%;
	}
	&-60 {
		width: 50%;
	}
	&-70 {
		width: 50%;
	}
	&-75 {
		width: 75%;
	}
	&-80 {
		width: 50%;
	}
	&-90 {
		width: 50%;
	}
	&-100 {
		width: 100%;
	}
}

/*************************/
/******** ALERTS *********/
/*************************/
.q-position-engine {
	font-size: 11px;
	@media (max-width: $breakpoint-sm-max) {
		font-size: 8px;
	}
	@media (min-width: $breakpoint-xl-min) {
		font-size: 15px;
	}
	.q-item {
		padding: 0.61vw 1.23vw;
		@media (max-width: $breakpoint-sm-max) {
			padding: 1.11vw 1.73vw;
		}
	}
}

/*****************************/
/****** TEXT OVERRIDES *******/
/*****************************/
.text-h5 {
	font-size: 20px;
	@media (max-width: $breakpoint-sm-max) {
		font-size: 16px;
	}
	@media (min-width: $breakpoint-xl-min) {
		font-size: 24px;
	}
}

.text-h6 {
	font-size: 16px;
	@media (max-width: $breakpoint-sm-max) {
		font-size: 13px;
	}
	@media (min-width: $breakpoint-xl-min) {
		font-size: 20px;
	}
}

//dashboard css
.carousel__viewport {
	position: absolute;
	width: 100%;
}
.carousel {
	.carousel__prev,
	.carousel__next {
		visibility: hidden;
	}
}

.dashBoardCalendar {
	.q-date__main.col.column {
		flex-wrap: nowrap !important;
	}
}
